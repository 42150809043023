import CertificationServices from '../../../api/services/certificationServices';

async function CrtfNote(studentId) {
  const formatData = {};
  let data = [];
  let loading;
  let error = false;

  try {
    data = await CertificationServices.getCrtfNoteByStudentId(studentId);
    data.forEach((item) => {
      formatData[item.certificate_id] = {
        check: item.check,
        crtfId: item.certificate_id,
        crtfTitle: 'Pending',
        content: item.note,
      };
    });

    loading = false;
  } catch (e) {
    error = e;
    loading = false;
  }

  return [formatData, loading, error];
}

export default CrtfNote;
