import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

function GradeTextField({ grade, setGrade }) {
  const [localValue, setLocalValue] = useState(grade);

  const localHandleTypeGrade = (event) => {
    setLocalValue(event.target.value.toUpperCase());
  };

  const handleTypeGrade = (event) => {
    setGrade(event.target.value.toUpperCase());
  };

  return (
    <TextField
      sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
      value={localValue || ''}
      onChange={localHandleTypeGrade}
      onBlur={handleTypeGrade}
    />
  );
}

export default GradeTextField;
