import React from 'react';
import { CURRENT, GRADEPOINTS, ENROLLSTATUS } from '../../../ENV';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import StudentTable from '../../StudentReport/StudentTable';
import DownloadPdFButton from '../../../components/DownloadPdFButton';
import { useHistory } from 'react-router-dom';

export default function GradeTable({ studentList }) {
  const history = useHistory();

  const mutateData = () => {
    let filtratedStudentList = studentList.filter(
      (item) =>
        item.enrollment_status === ENROLLSTATUS.ACTIVE ||
        item.enrollment_status === ENROLLSTATUS.PROBATION ||
        item.enrollment_status === ENROLLSTATUS.ABSENT
    );
    const regex = /#PASSCREDIT[1-6]{1}/;
    const semester_order = ['Spring', 'Summer', 'Fall'];
    let cutoff_year = 1900;
    let cutoff_semester = '';
    // Determine the cutoff semester and year to calculate the last grade
    if (CURRENT.TERM == 'Spring') {
      cutoff_year = CURRENT.YEAR - 1;
      cutoff_semester = 'Summer';
    } else if (CURRENT.TERM == 'Summer') {
      cutoff_year = CURRENT.YEAR - 1;
      cutoff_semester = 'Fall';
    } else {
      cutoff_year = CURRENT.YEAR - 1;
      cutoff_semester = 'Spring';
    }
    // Add grade points in student array
    filtratedStudentList.map((student) => {
      let totalPoints = 0;
      let totalCourses = 0;
      let totalCredits = 0;
      let totalLastPoints = 0;
      let totalLastCourses = 0;
      student.courses.map((course) => {
        if (GRADEPOINTS.hasOwnProperty(course.grade)) {
          // Current GPA
          totalPoints += GRADEPOINTS[course.grade];
          totalCourses += 1;
          if (course.note && regex.test(course.note)) {
            totalCredits += Number(course.note.match(regex)[0].slice(11));
          } else {
            totalCredits += course.credit;
          }
          // Last GPA
          if (
            course.year < cutoff_year ||
            (course.year == cutoff_year) &
              (semester_order.indexOf(course.term) <=
                semester_order.indexOf(cutoff_semester))
          ) {
            totalLastPoints += GRADEPOINTS[course.grade];
            totalLastCourses += 1;
          }
        }
      });
      if (totalCourses) {
        student.gpa = (totalPoints / totalCourses).toFixed(2);
      } else {
        student.gpa = 'N/A';
      }
      if (totalLastCourses) {
        student.lastGPA = (totalLastPoints / totalLastCourses).toFixed(2);
      } else {
        student.lastGPA = 'N/A';
      }
      student.totalCredits = totalCredits;
    });
    return filtratedStudentList;
  };

  const renderExptGrad = (params) => {
    if (CURRENT.YEAR + ' ' + CURRENT.TERM == params.row.concatGradTerm) {
      return (
        <div style={{ backgroundColor: 'yellow' }}>
          {params.row.concatGradTerm}
        </div>
      );
    } else {
      <div>{params.row.concatGradTerm}</div>;
    }
  };
  const renderGPAChange = (params) => {
    let score =
      (parseFloat(params.row.gpa) || 0) - (parseFloat(params.row.lastGPA) || 0);
    if (score > 0) {
      return (
        <Tooltip title={`Last Grade: ${params.row.lastGPA}`}>
          <div style={{ display: 'inline-block', color: 'green' }}>
            {params.row.gpa}&nbsp;&nbsp;↑
          </div>
        </Tooltip>
      );
    } else if (score < 0) {
      return (
        <Tooltip title={`Last Grade: ${params.row.lastGPA}`}>
          <div style={{ display: 'inline-block', color: 'red' }}>
            {params.row.gpa}&nbsp;&nbsp;↓
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={`Last Grade: ${params.row.lastGPA}`}>
          <div style={{ display: 'inline-block', color: 'black' }}>
            {params.row.gpa}&nbsp;&nbsp;-
          </div>
        </Tooltip>
      );
    }
  };

  const handleToPOS = (id) => {
    history.push({
      pathname: '/CoursePlan',
      search: `${id}`,
    });
  };

  const renderButton = (params) => (
    <ButtonGroup disableElevation variant="contained" size="medium">
      <Button onClick={() => handleToPOS(params.id)}>View POS</Button>
      <DownloadPdFButton studentData={params.row} />
    </ButtonGroup>
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'concatGradTerm',
      headerName: 'Expt Grad',
      width: 180,
      renderCell: renderExptGrad,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'enrollment_status',
      headerName: 'Enrollment Status',
      type: 'string',
      width: 180,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'gpa',
      headerName: 'Grade',
      type: 'string',
      width: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderGPAChange,
    },
    {
      field: 'totalCredits',
      headerName: 'Total Credits',
      type: 'string',
      width: 180,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 250,
      renderCell: renderButton,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
    },
  ];

  return <StudentTable studentList={mutateData()} headConfig={columns} />;
}
