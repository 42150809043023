import _ from 'underscore';

/**
 * @description - To extract the credit from the note
 * @param {string} str - a string that should be tested
 * @return {number} - return the passcode number
 */
function passcodeExtractor(str) {
  const regex = /#PASSCREDIT[1-6]{1}/;
  if (str && regex.test(str)) {
    return Number(str.match(regex)[0].slice(11));
  }
  return 3;
}

/**
 * @description - To find the matched string with the regex
 * @param {string} str - a string that should be tested
 * @return {string} - return the matched string with the regex
 */
function passcodeFitter(str) {
  const regex = new RegExp('#PASSCREDIT[1-6]{1}');
  if (str && regex.test(str)) {
    return str.match(regex)[0];
  }
  return null;
}

/**
 * @description - To check whether the string is matched with the regex
 * @param {string} str - a string that should be tested
 * @return {boolean} - return true if the string is matched with the regex
 */
function passcodeTester(str) {
  const regex = new RegExp('#PASSCREDIT[1-6]{1}');
  return regex.test(str);
}

/**
 * @param {boolean} booleanFlag
 * @param {*} source
 */
function isEquivalent(booleanFlag, source) {
  if (!booleanFlag) {
    return true;
  }
  if (booleanFlag) {
    return booleanFlag === !source;
  }
  return true;
}

/**
 *
 * @param {[Object]} sourceArr - an arrary which element is object
 * @param sourcePropertyName - property name
 * @param {Object} currTarget - current element which we wanna know whether is included in array
 * @return {boolean}
 */
function isIncluded(sourceArr, sourcePropertyName, currTarget) {
  for (const item of sourceArr) {
    if (item[sourcePropertyName] == currTarget) {
      return true;
    }
  }

  return false;
}

/**
 * To check whether query body missing any property.
 * @param {JSON} jsonObj
 * @param {Array[String]} escapePropArray Array that contain name of properties that can be empty
 * @param {Boolean} flipSwitch Switch escapePropArray TO BE CHECK array if true
 * @return {string} return 'PASS' if all properties have content
 */
function missingPropertyCheck(jsonObj, escapePropArray, flipSwitch) {
  if (!flipSwitch) {
    for (const key in jsonObj) {
      if (!jsonObj[key] && !_.contains(escapePropArray, key)) {
        return key;
      }
    }
  } else {
    for (const key of escapePropArray) {
      if (!jsonObj[key]) {
        return key;
      }
    }
  }
  return 'PASS';
}

/**
 *
 * @param {string} email
 * @return {string}
 */
function passwordAlgorithm(email) {
  return email.split('@')[0] + 'Abccba#';
}
/**
 * @param {number} role
 * @param {String} email
 * @param {String=} firstName - Somebody's first name
 * @param {String=} lastName - Somebody's last name
 * @param {boolean} [confirmed = true]
 * @param {boolean} [blocked = false]
 * @return {{password: string, userName: String}}
 */
function generateAccount(
  role,
  email,
  firstName,
  lastName,
  confirmed = true,
  blocked = false
) {
  const password = passwordAlgorithm(email);
  return {
    role,
    username: firstName + ' ' + lastName,
    email,
    password,
    confirmed,
    blocked,
  };
}

// eslint-disable-next-line import/prefer-default-export
export {
  isEquivalent,
  isIncluded,
  missingPropertyCheck,
  passwordAlgorithm,
  generateAccount,
  passcodeFitter,
  passcodeTester,
  passcodeExtractor,
};
