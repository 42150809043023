import { API } from '../api';

const CourseServices = {
  /**
   *
   * @param {Object} query
   * @return {Promise<AxiosResponse<any>>}
   */
  getCoursesForStudent(query) {
    return API.post(`/courses/getCoursesForStudent`, query).then(
      (res) => res.data
    );
  },
};

export default CourseServices;
