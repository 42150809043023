import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export default function DownloadAttachment({ url, filename }) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const download = () => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one');
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';
        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };

  return (
    <Button
      disabled={fetching}
      onClick={() => download()}
      aria-label="download"
      style={{ margin: '5px' }}
    >
      Download
    </Button>
  );
}
