import React from 'react';
import { Grid, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function StudentReport() {
  const history = useHistory();

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/graduation-report');
          }}
        >
          Graduation Report
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/requirement-report');
          }}
        >
          Requirement Report
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/cohort-view');
          }}
        >
          Cohort View
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/grade-report');
          }}
        >
          Grade Report
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/student-report/download-files');
          }}
        >
          Access Files
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: 180 }}
          variant="contained"
          onClick={() => {
            history.push('/student-report/template-validation');
          }}
        >
          Template Validation
        </Button>
      </Grid>
    </Grid>
  );
}
