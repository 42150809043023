import React from 'react';
import { Space, Table, Tag } from 'antd';

const columns = [
  {
    title: 'Term',
    dataIndex: 'term',
    key: 'term',
    width: 100,
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Conflict',
    dataIndex: 'isConflict',
    key: 'isConflict',
    width: 100,
    render: (_, { isConflict }) => (
      <Tag color={isConflict ? 'red' : 'green'}>{isConflict ? 'Y' : 'N'}</Tag>
    ),
  },
  {
    title: 'Conflict Report',
    dataIndex: 'conflictReport',
    key: 'conflictReport',
    width: 150,
    render: (conflictReport) => (
      <Space direction="vertical">
        {conflictReport.map((item) => (
          <div key={item}>
            <div>
              {item.courseTerm} term has 2 classes on {item.courseDay}
            </div>
          </div>
        ))}
      </Space>
    ),
  },
  {
    title: 'Missing',
    dataIndex: 'isMissing',
    key: 'isMissing',
    width: 100,
    render: (_, { isMissing }) => (
      <Tag color={isMissing ? 'red' : 'green'}>{isMissing ? 'Y' : 'N'}</Tag>
    ),
  },
  {
    title: 'Missing Report',
    dataIndex: 'missingReport',
    key: 'missingReport',
    width: 150,
    render: (missingReport) => (
      <Space direction="vertical">
        {missingReport.map((item) => (
          <div key={item}>
            <div>{item.courseId + item.courseTerm + item.courseDay}</div>
          </div>
        ))}
      </Space>
    ),
  },
];

export default function TemplateValidationTable({ id, data, title }) {
  return (
    <Table
      key={id}
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      title={() => title}
    />
  );
}
