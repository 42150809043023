import { API } from '../api';
import mediaService from './mediaService';

const NotesServices = {
  async AddNotes(noteData) {
    return API.post(`/notes`, noteData).then((res) => res.data);
  },

  async DeleteNotes(itemId) {
    return API.delete(`/notes/${itemId}`).then((res) => res.data);
  },

  async EditNotes(noteData) {
    return API.put(`/notes/${noteData.id}`, noteData).then((res) => res.data);
  },

  async SaveNotesAndAttachments(noteObj, attachList) {
    const newNote = await this.AddNotes(noteObj);
    if (attachList.length > 0) {
      await mediaService.uploadNotesAttach(attachList, [], newNote.id);
    }
  },

  async UpdateNotesAndAttachments(noteObj, attachList) {
    await this.EditNotes(noteObj);
    const keepFiles = [];
    const removeFileIds = [];
    const newFiles = [];
    attachList.forEach((attachment) => {
      if (attachment.id) {
        if (
          attachment.isDisplay === undefined ||
          attachment.isDisplay === true
        ) {
          delete attachment.isDisplay;
          keepFiles.push(attachment);
        } else {
          removeFileIds.push(attachment.id);
        }
      } else {
        newFiles.push(attachment);
      }
    });

    if (removeFileIds.length > 0) {
      await mediaService.deleteFiles(removeFileIds);
    }
    if (keepFiles.length > 0 || newFiles.length > 0) {
      await mediaService.uploadNotesAttach(newFiles, keepFiles, noteObj.id);
    }
  },

  async FetchStudentNotes(query, fetchConfig) {
    let data = [];
    let loading = true;
    let error = false;

    try {
      const result = await API.post(
        `/notes/getNotesForStudent`,
        query,
        fetchConfig
      );
      data = result.data;
      loading = false;
    } catch (e) {
      error = e;
      loading = false;
    }
    return [loading, error, data];
  },
};

export default NotesServices;
