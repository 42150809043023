import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children, ...rest }) {
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );

  return (
    <Route
      {...rest}
      render={() =>
        authenticationStatus.authenticationRole !== false ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default withRouter(PrivateRoute);
