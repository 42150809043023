import useSWR from 'swr';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import programServices from '../services/programServices';

export default function useCertifProgInfo() {
  const { data, error, isLoading } = useSWR(
    true,
    programServices.getCertificationPrograms
  );

  return {
    data,
    isLoading,
    isError: error ? errorMssgExtract(error) : error,
  };
}
