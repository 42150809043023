import React from 'react';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

function FalseCheck(props) {
  return (
    <Checkbox
      {...props}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<DisabledByDefaultIcon />}
    />
  );
}

export default FalseCheck;
