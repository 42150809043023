/* eslint-disable no-continue */
/* eslint-disable no-unused-expressions */

import { isEmpty } from 'underscore';

const getTotalCredit = (courseData, isPDF) => {
  let total = 0;
  if (isPDF) {
    courseData.forEach((item) => {
      total +=
        item.courseTakenCredit && item.courseGrade
          ? Number(item.courseTakenCredit)
          : 0;
    });
  } else {
    courseData.forEach((item) => {
      total +=
        item.courseCredit && item.courseGrade ? Number(item.courseCredit) : 3;
    });
  }
  return total;
};

const convertTemplateToCourseArr = (template) => {
  const result = [];
  template.Required &&
    Object.entries(template.Required).forEach(([key, value]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        result.push({
          courseId,
          courseTitle: value,
        });
      }
    });
  template.Elective &&
    Object.entries(template.Elective).forEach(([key, value]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        result.push({
          courseId,
          courseTitle: value,
        });
      }
    });
  return result;
};

const assembleCourseAndSelection = (courseArr, currentSelectionArr) => {
  for (let i = 0; i < courseArr.length; i += 1) {
    const obj = courseArr[i];
    courseArr[i].courseTerm = '';
    courseArr[i].courseYear = '';
    courseArr[i].courseDay = '';
    courseArr[i].courseTime = '';
    courseArr[i].courseGrade = '';
    courseArr[i].courseUid = '';
    courseArr[i].isExpired = false;
    // Match
    for (let j = 0; j < currentSelectionArr.length; j += 1) {
      const obj2 = currentSelectionArr[j];
      if (obj.courseId == obj2.courseId) {
        courseArr[i].courseTerm = obj2.courseTerm;
        courseArr[i].courseYear = obj2.courseYear;
        courseArr[i].courseDay = obj2.courseDay;
        courseArr[i].courseTime = obj2.courseTime;
        courseArr[i].courseGrade = obj2.courseGrade;
        courseArr[i].courseUid = obj2.courseUid;
        courseArr[i].isExpired = obj2.isExpired;
      }
    }
  }
  return courseArr;
};

// Return array of course number
const groupCourseData = (template, sourceData) => {
  const pickUp = (courseData, matchData, target) => {
    for (let i = 0; i < matchData.length; i += 1) {
      let indexFlag = -1;
      courseData.forEach((item, index) => {
        if (Number(item.courseId) === matchData[i]) {
          target.push(item);
          indexFlag = index;
        }
      });
      if (indexFlag >= 0) {
        courseData.splice(indexFlag, 1);
      }
    }
  };

  // Generate Course Order
  const requiredCourseOrder = [];
  const electiveCourseOrder = [];
  template.Required &&
    Object.entries(template.Required).forEach(([key]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        requiredCourseOrder.push(courseId);
      }
    });
  template.Elective &&
    Object.entries(template.Elective).forEach(([key]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        electiveCourseOrder.push(courseId);
      }
    });

  const cloneSourceData = [...sourceData];
  const requiredCourses = [];
  const electiveCourses = [];

  pickUp(cloneSourceData, requiredCourseOrder, requiredCourses);
  pickUp(cloneSourceData, electiveCourseOrder, electiveCourses);

  return [requiredCourses, electiveCourses, cloneSourceData];
};

/**
 * Return true if track is a certificate track
 * @param {String} track
 * @returns {boolean}
 */
const checkCertiTrack = (track) => track && track.indexOf('C') === 0;

/**
 *
 * @param {Object} course
 * @param {String} gradeProperty
 * @param {String} semesterProperty
 * @return {boolean}
 */
const isTaken = (course, gradeProperty, semesterProperty) => {
  if (!isEmpty(course[gradeProperty])) {
    return true;
  }
  return !isEmpty(course[semesterProperty]);
};

/**
 * Return true if courseArr contains determined by courseId
 * @param {String|Number} courseId
 * @param {Array[Object]} courseArr
 * @return {boolean}
 */
const containsCourse = (courseId, courseArr) => {
  for (let i = 0; i < courseArr.length; i += 1) {
    if (courseArr[i].courseId == courseId) {
      return true;
    }
  }
  return false;
};

export {
  getTotalCredit,
  groupCourseData,
  assembleCourseAndSelection,
  convertTemplateToCourseArr,
  checkCertiTrack,
  isTaken,
  containsCourse,
};
