import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { IconButton, Typography, Toolbar, AppBar, Box } from '@mui/material';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { drawerWidth } from '../../ENV';
import { updateAuthStatus } from '../../store/reducer/authenticationSlice';
import Profile from '../Profile';
import SearchBar from './SearchBar';

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 100,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ open, handleDrawerOpenToggle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userName = localStorage.getItem('currentUserName');
  const handleLogout = () => {
    localStorage.clear();
    dispatch(updateAuthStatus(null));
    history.push('/');
  };

  const theme = useTheme();
  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';

  const appBar = {
    flexgrow: 1,
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    <AppBarStyled open={open} {...appBar}>
      <Toolbar>
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerOpenToggle}
          edge="start"
          color="secondary"
          sx={{
            color: 'text.primary',
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            ml: { xs: 0, lg: -1 },
            p: 1,
          }}
        >
          {!open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: '100%',
            ml: { xs: 0, md: 1 },
          }}
        >
          {/* @TODO: Implement in the future */}
          {/* <SearchBar /> */}
          <Typography variant="h5" noWrap component="div" sx={{ ml: 1 }}>
            Health Informatics Hub
          </Typography>
        </Box>
        <Profile userName={userName} logoutFunction={handleLogout} />
      </Toolbar>
    </AppBarStyled>
  );
}

export default Header;
