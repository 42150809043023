/* eslint-disable object-shorthand */
import React, { useState } from 'react';
import { TableCell, TableRow, Tooltip, Chip } from '@mui/material';
import {
  PriorityHighOutlined,
  HideSourceOutlined,
  DangerousOutlined,
} from '@mui/icons-material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useNonInitUpdateEffect from '../../../../hooks/useNonInitUpdateEffect';
import DayTimeDropDown from './DayTimeDropDown';
import TermDropDown from './TermDropDown';
import YearDropDown from './YearDropDown';
import { isValidTerm, isValidYear } from '../../../../utils/termTools';
import { isAccurate, isComplete, isConsistent, isSelected } from '../../utils';
import GradeTextField from './GradeTextField';
import NoteTextField from './NoteTextField';
import CourseCreditBackdrop from './CourseCreditBackdrop';
import { passcodeExtractor } from '../../../../utils/generalTools';

function CourseRow({ isRequired, courseData, currSelDispatch, transactionId }) {
  const [course, setCourse] = useState(courseData);
  const [day, setDay] = useState(course.courseDay);
  const [time, setTime] = useState(course.courseTime);
  const [term, setTerm] = useState(course.courseTerm);
  const [year, setYear] = useState(course.courseYear);
  const [grade, setGrade] = useState(course.courseGrade);
  const [note, setNote] = useState(course.courseNote);
  const [type, setType] = useState(course.courseType);
  const [dbSelectionId, setDbSelectionId] = useState(course.db_selectionId);
  const [suid, setSuid] = useState(course.suid);
  const [isOffered, setIsOffered] = useState(course.isOffered);
  const [open, setOpen] = useState(false);

  // Update state
  useNonInitUpdateEffect(() => {
    setCourse(courseData);
    setDay(courseData.courseDay);
    setTime(courseData.courseTime);
    setTerm(courseData.courseTerm);
    setYear(courseData.courseYear);
    setGrade(courseData.courseGrade);
    setNote(courseData.courseNote);
    setType(courseData.courseType);
    setSuid(courseData.suid);
  }, [
    courseData,
    courseData.courseDay,
    courseData.courseTime,
    courseData.courseTerm,
    courseData.courseYear,
    courseData.courseGrade,
    courseData.courseNote,
    courseData.courseType,
    courseData.suid,
  ]);

  // Subscribe/Unsubscribe
  useNonInitUpdateEffect(() => {
    if (!time && !day && !term && !year && !grade && !note) {
      currSelDispatch({
        type: 'REMOVE_SELECTION',
        payload: { id: suid },
      });
    } else {
      currSelDispatch({
        type: 'UPDATE_SELECTION',
        payload: {
          course,
          time,
          day,
          term,
          year,
          grade,
          note,
          type,
          transactionId,
        },
      });
    }
  }, [time, day, term, year, grade, note, type]);

  // if (!courseData) {
  //   return <ErrorPage />;
  // }

  const displayTag = () => {
    let isOfferedSection = true;
    let isOfferedSectionList = course.courseData
      .filter((data) => data.uid === course.courseUid)
      .map((data) => data.is_offered);
    if (isOfferedSectionList.length > 0) {
      if (isOfferedSectionList[0] == false) {
        isOfferedSection = false;
      }
    }

    if (!isOfferedSection) {
      return (
        <Tooltip title="Not offered section">
          <DangerousOutlined />
        </Tooltip>
      );
    }

    if (!isOffered) {
      return (
        <Tooltip title="Not offered course">
          <DangerousOutlined />
        </Tooltip>
      );
    }
    if (
      isSelected(time, day, term, year) &&
      !isComplete(time, day, term, year)
    ) {
      return (
        <Tooltip title="Incomplete Selection">
          <HideSourceOutlined />
        </Tooltip>
      );
    }

    if (
      isSelected(time, day, term, year) &&
      isComplete(time, day, term, year) &&
      !isAccurate(course, time, day, term, year)
    ) {
      return (
        <Tooltip title="Inaccurate Selection">
          <PriorityHighOutlined />
        </Tooltip>
      );
    }

    // if (
    //   isSelected(time, day, term, year) &&
    //   isComplete(time, day, term, year) &&
    //   !isConsistent(type)
    // )
    return <></>;
  };

  const displayDayAndTime = () => {
    let availOptions = [];
    let unavailOptions = [];
    if (isValidTerm(term) && isValidYear(year)) {
      availOptions = course.courseOptions[term];
      unavailOptions = course.unofferedCourseOptions[term];
    }
    return (
      <DayTimeDropDown
        options={availOptions}
        unOptions={unavailOptions}
        selectedTime={time}
        setTime={setTime}
        selectedDay={day}
        setDay={setDay}
        type={type}
        setType={setType}
      />
    );
  };

  const displayTerm = () => (
    <TermDropDown
      courseAvailTerm={course.courseAvailTerm}
      courseUnavailTerm={course.courseUnavailTerm}
      selectedTerm={term}
      setTerm={setTerm}
      setDay={setDay}
      setTime={setTime}
    />
  );

  const useDisplayCourseTitle = () => {
    const [creditValue, setCreditValue] = useState(passcodeExtractor(note));
    if (course.courseId == 2999) {
      const editCourseCredit = ({ credit }) => {
        setCreditValue(credit);
        setOpen(false);
        const regex = /#PASSCREDIT[1-6]{1}/;
        if (note && regex.test(note)) {
          const newNote = note.replace(regex, `#PASSCREDIT${credit}`);
          setNote(newNote);
        } else {
          setNote(`#PASSCREDIT${credit}`);
        }
      };
      return (
        <div>
          {course.courseTitle}
          {/*<IconButton*/}
          {/*  aria-label="edit icon"*/}
          {/*  size="small"*/}
          {/*  color="info"*/}

          {/*>*/}
          {/*</IconButton>*/}
          <Chip
            sx={{ ml: 1 }}
            label={creditValue}
            variant="outlined"
            color="primary"
            size="small"
          />
          <Chip
            label="Edit Credit"
            sx={{ ml: 1 }}
            size="small"
            color="primary"
            avatar={<EditRoundedIcon color="primary" />}
            onClick={() => setOpen(!open)}
          />
          <CourseCreditBackdrop
            open={open}
            handleClose={() => setOpen(!open)}
            handleSubmitFunction={editCourseCredit}
          />
        </div>
      );
    }
    if (course.courseTitle.length > 80) {
      return course.courseTitle.substring(0, 80) + '...';
    }
    return course.courseTitle;
  };

  return (
    // Bolded required courses
    <TableRow
      key={course.courseId + course.db_selectionId}
      // hover
      sx={{
        ...(isRequired ? { '& td': { fontWeight: 'bold' } } : {}),
        ...(isOffered ? {} : { '& td': { color: 'rgb(180, 180, 180)' } }),
      }}
    >
      <TableCell>{displayTag()}</TableCell>
      <TableCell>{course.courseId}</TableCell>
      <TableCell>{useDisplayCourseTitle()}</TableCell>
      <TableCell>{displayDayAndTime()}</TableCell>
      <TableCell>{displayTerm()}</TableCell>
      <TableCell>
        <YearDropDown
          courseAvailYear={course.courseAvailYear}
          selectedYear={year}
          setYear={setYear}
          setTerm={setTerm}
          setDay={setDay}
          setTime={setTime}
        />
      </TableCell>
      <TableCell>
        <GradeTextField grade={grade} setGrade={setGrade} />
      </TableCell>
      <TableCell>
        <NoteTextField note={note} setNote={setNote} />
      </TableCell>
    </TableRow>
  );
}

export default CourseRow;
