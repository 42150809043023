import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  Chip,
  createFilterOptions,
  TextField,
} from '@mui/material';

const filter = createFilterOptions();

function AutoCompleteTag({
  id,
  title,
  disableFlag,
  optionData,
  value,
  setValue,
}) {
  const [highlightOption, setHighlightOption] = useState(null);
  const [open, setOpen] = React.useState(false);

  const addOption = useCallback(
    (optionToAdd) => {
      const options = value;
      // if there are duplicated options added, ignore it
      if (options.find((option) => option.value === optionToAdd.value)) {
        return false;
      }

      setValue([...options, optionToAdd]);
      return true;
    },
    [value, setValue]
  );
  return (
    <Autocomplete
      disabled={disableFlag}
      multiple
      autoHighlight
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text"
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        width: '100ch',
      }}
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: inputValue,
            label: inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      options={optionData}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li
          {...props}
          onClick={(event) => {
            event.defaultMuiPrevented = true;
            addOption(option);
            setOpen(false);
          }}
        >
          {option.title}
        </li>
      )}
      renderInput={(params) => <TextField {...params} />}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            sx={{
              '& .MuiChip-label': {
                paddingLeft: 0,
              },
            }}
            {...getTagProps({ index })}
            label={option.label}
          />
        ))
      }
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.defaultMuiPrevented = true;
          const newValue = event.target.value;
          const optionToAdd = highlightOption || {
            value: newValue,
            label: newValue,
            title: newValue,
          };
          addOption(optionToAdd);
          setOpen(false);
        }
      }}
      onHighlightChange={(event, option) => {
        setHighlightOption(option);
      }}
    />
  );
}

export default AutoCompleteTag;
