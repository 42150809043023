import React, { useEffect, useState } from 'react';
import { Accordion, Button, Table } from 'react-bootstrap';
import { Form, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Box from '@mui/material/Box';
import ViewOrEditSelector from '../ViewOrEditSelector';
import InfoData from '../InfoData';
import ViewOrEditField from '../ViewOrEditField';
import ViewOrEditSlider from '../ViewOrEditSlider';
import { CONFIG } from '../../../ENV';

export default function AcademicInfo({
  studentObj,
  isDisabled,
  setIsDisabled3,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  update,
}) {
  const student = studentObj;
  const sessionId = 3;
  const [medTerminologyFlag, setMedTerminologyFlag] = useState(
    student.medical_terminology ? student.medical_terminology : false
  );
  const [dbAdvisorInfo, setdbAdvisorInfo] = useState([]);
  const userName = localStorage.getItem('currentUserName');

  const advisorOptions = [];
  Object.keys(dbAdvisorInfo).forEach((key) => {
    advisorOptions.push({
      value: dbAdvisorInfo[key].name,
      label: dbAdvisorInfo[key].name,
    });
  });

  const isSliderTrue = (change, sliderName) => {
    if (sliderName === 'medical_terminology') {
      setMedTerminologyFlag(change);
    }
  };

  const yearParser = (target) => {
    if (student[target]) {
      return Date.parse(Number(student[target]) + 1);
    }
    return Date.parse('');
  };

  useEffect(async () => {
    const ReadFromAdvisors = async () => {
      const authStat = JSON.parse(localStorage.getItem('authenticationStatus'));
      if (authStat.authenticationRole === 'Faculty') {
        setdbAdvisorInfo([{ name: userName }]);
      } else {
        const { data } = await axios.get(`${CONFIG.PORTAL}/Advisors`, {
          headers: {
            Authorization: `Bearer ${authStat.jwtToken}`,
          },
        });
        setdbAdvisorInfo({
          ...data,
        });
      }
    };
    await ReadFromAdvisors();
  }, []);

  return (
    <Accordion
      defaultActiveKey={['0']}
      alwaysOpen
      style={{ marginTop: '20px' }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Academic Information</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Table borderless>
              <tbody>
                <tr>
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Academic Track"
                    fieldValue={student.track}
                    isDisabled={isDisabled}
                    studentInfoKey="track"
                    selectOptions={InfoData.trackOptions}
                  />
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Academic Type"
                    fieldValue={student.type}
                    isDisabled={isDisabled}
                    studentInfoKey="type"
                    selectOptions={InfoData.typeOptions}
                  />
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Academic Status"
                    fieldValue={student.status}
                    isDisabled={isDisabled}
                    studentInfoKey="status"
                    selectOptions={InfoData.statusOptions}
                  />
                </tr>

                {advisorOptions.length > 1 && (
                  <tr>
                    <ViewOrEditSelector
                      updateInfo={update}
                      fieldName="Advisor"
                      fieldValue={student.advisor}
                      isDisabled={isDisabled}
                      studentInfoKey="advisor"
                      selectOptions={advisorOptions}
                    />
                  </tr>
                )}
                <tr>
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Admission GPA"
                    fieldValue={student.admission_gpa}
                    isDisabled={isDisabled}
                    studentInfoKey="admission_gpa"
                  />
                </tr>
                <tr>
                  <td>
                    <Label for="start_year">Start Year</Label>
                    <DatePicker
                      disabled={isDisabled}
                      customInput={<Input />}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      closeOnScroll
                      selected={yearParser('start_year')}
                      onChange={(date) => {
                        if (date) {
                          update(
                            date.toLocaleDateString('en-GB', {
                              year: 'numeric',
                            }),
                            'start_year'
                          );
                        } else {
                          update(null, 'start_year');
                        }
                      }}
                      placeholderText="Start Year"
                    />
                  </td>
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Start Term"
                    fieldValue={student.start_term}
                    isDisabled={isDisabled}
                    studentInfoKey="start_term"
                    selectOptions={InfoData.termOptions}
                  />
                </tr>
                <tr>
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Expect Grad Year"
                    fieldValue={student.expected_grad_year}
                    isDisabled
                    studentInfoKey="expected_grad_year"
                    hidden={student.id <= 0}
                  />
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Expect Grad Term"
                    fieldValue={student.expected_grad_term}
                    isDisabled
                    studentInfoKey="expected_grad_term"
                    selectOptions={InfoData.termOptions}
                    hidden={student.id <= 0}
                  />
                </tr>
                <tr>
                  <td>
                    <Label for="actual_grad_year">Actual Grad Year</Label>
                    <DatePicker
                      disabled={isDisabled}
                      customInput={<Input />}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      closeOnScroll
                      selected={yearParser('actual_grad_year')}
                      onChange={(date) => {
                        if (date) {
                          update(
                            date.toLocaleDateString('en-GB', {
                              year: 'numeric',
                            }),
                            'actual_grad_year'
                          );
                        } else {
                          update(null, 'actual_grad_year');
                        }
                      }}
                      placeholderText="Actual Grad Year"
                    />
                  </td>
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Actual Grad Term"
                    fieldValue={student.actual_grad_term}
                    isDisabled={isDisabled}
                    studentInfoKey="actual_grad_term"
                    selectOptions={InfoData.termOptions}
                  />
                </tr>
                <tr>
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Capstone Internship"
                    fieldValue={student.capstone_internship}
                    isDisabled={isDisabled}
                    studentInfoKey="capstone_internship"
                  />
                </tr>
              </tbody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
              }}
            >
              <ViewOrEditSlider
                updateInfo={update}
                fieldName="Title IX Status"
                fieldValue={student.title_ix_status}
                isDisabled={isDisabled}
                studentInfoKey="title_ix_status"
                isSliderTrue={isSliderTrue}
              />
              <ViewOrEditSlider
                updateInfo={update}
                fieldName="HIPAA Training"
                fieldValue={student.hipaa_training}
                isDisabled={isDisabled}
                studentInfoKey="hipaa_training"
                isSliderTrue={isSliderTrue}
              />
              <ViewOrEditSlider
                updateInfo={update}
                fieldName="Security clearance"
                fieldValue={student.security_clearance}
                isDisabled={isDisabled}
                studentInfoKey="security_clearance"
                isSliderTrue={isSliderTrue}
              />
              <ViewOrEditSlider
                updateInfo={update}
                fieldName="Final POS with Grades"
                fieldValue={student.final_pos_grades}
                isDisabled={isDisabled}
                studentInfoKey="final_pos_grades"
                isSliderTrue={isSliderTrue}
              />
              <ViewOrEditSlider
                updateInfo={update}
                fieldName="RAMPed"
                fieldValue={student.ramped}
                isDisabled={isDisabled}
                studentInfoKey="ramped"
                isSliderTrue={isSliderTrue}
              />
            </Box>
            <Table borderless="true">
              <tbody>
                <tr>
                  <td style={{ paddingLeft: '0px' }}>
                    <ViewOrEditSlider
                      updateInfo={update}
                      fieldName="Medical Terminology"
                      fieldValue={student.medical_terminology}
                      isDisabled={isDisabled}
                      studentInfoKey="medical_terminology"
                      isSliderTrue={isSliderTrue}
                    />
                  </td>
                  <td>
                    <Label for="med_terminology_year">Fulfilled Year</Label>
                    <DatePicker
                      disabled={isDisabled || !medTerminologyFlag}
                      customInput={<Input />}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      closeOnScroll
                      selected={yearParser('med_terminology_year')}
                      onChange={(date) => {
                        if (date) {
                          update(
                            date.toLocaleDateString('en-GB', {
                              year: 'numeric',
                            }),
                            'med_terminology_year'
                          );
                        } else {
                          update(null, 'med_terminology_year');
                        }
                      }}
                      placeholderText="Fulfilled Year"
                    />
                  </td>
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Fulfilled Term"
                    fieldValue={student.med_terminology_term}
                    isDisabled={isDisabled || !medTerminologyFlag}
                    studentInfoKey="med_terminology_term"
                    selectOptions={InfoData.termOptions}
                  />
                  <ViewOrEditSelector
                    updateInfo={update}
                    fieldName="Fulfilled Way"
                    fieldValue={student.med_terminology_fulfill}
                    isDisabled={isDisabled || !medTerminologyFlag}
                    studentInfoKey="med_terminology_fulfill"
                    selectOptions={InfoData.medTerminologyFulFilledOption}
                  />
                </tr>
              </tbody>
            </Table>

            <Button
              className={
                isDisabled && handleEditClick(sessionId)
                  ? 'col-1 me-3 btn-primary'
                  : 'col-1 me-3 btn-secondary'
              }
              onClick={() => {
                if (!(isDisabled && handleEditClick(sessionId))) {
                  handleCancelClick(sessionId);
                }
                if (handleEditClick(sessionId)) {
                  setIsDisabled3(!isDisabled);
                }
              }}
              hidden={student.id <= 0}
            >
              {' '}
              {isDisabled ? 'Edit ' : ' Cancel '}
            </Button>
            <Button
              className={
                isDisabled
                  ? 'col-1 me-3 btn-secondary'
                  : 'col-1 me-3 btn-primary'
              }
              onClick={() => handleSaveClick(sessionId)}
            >
              {' '}
              Save{' '}
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
