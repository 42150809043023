import React, { useReducer } from 'react';

const CertificationContextInitState = {};

const CertificationContext = React.createContext(CertificationContextInitState);

const CertificationContextReducer = (state, action) => {
  // Build-in Functions
  const deleteEntry = (org, target) => {
    const cloneCrtfState = { ...org };
    delete cloneCrtfState[target];
    return cloneCrtfState;
  };
  // Reducer
  switch (action.type) {
    case 'ADD_CRTF': {
      const crtf = action.payload;
      const cloneState = { ...state };
      cloneState[crtf.id] = {
        check: crtf.check,
        crtfId: crtf.id,
        crtfTitle: crtf.title,
        content: crtf.content,
      };
      return cloneState;
    }
    case 'REMOVE_CRTF':
      return deleteEntry(state, action.payload.id);
    case 'UPDATE_CRTF': {
      const crtf = action.payload;
      const cloneState = deleteEntry(state, crtf.id);
      cloneState[crtf.id] = {
        check: crtf.check,
        crtfId: crtf.id,
        crtfTitle: crtf.title,
        content: crtf.content,
      };
      return cloneState;
    }
    case 'RESET_CRTF':
      return CertificationContextInitState;
    case 'SET_CRTF':
      return action.payload;
    default:
      throw new Error('Not allowed Method');
  }
};

function CertificationContextProvider(props) {
  const [crtfState, crtfDispatch] = useReducer(
    CertificationContextReducer,
    CertificationContextInitState
  );

  return (
    <CertificationContext.Provider value={{ crtfState, crtfDispatch }}>
      {props.children}
    </CertificationContext.Provider>
  );
}

export { CertificationContext, CertificationContextProvider };
