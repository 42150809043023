import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { CONFIG } from '../../../ENV';
import { errorMssgExtract } from '../../../utils/errorHandleTools';

export default async function UploadStudentCSV(Pbody, targetId) {
  const extention = await getExtention(Pbody);
  const file = await fileToString(Pbody);
  if (extention === 'csv' || extention === 'xlsx') {
    return axios
      .post(
        `${CONFIG.PORTAL}/students/uploadStudentByFile`,
        { resume: file, type: extention },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('authenticationStatus')).jwtToken
            }`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        const msg = errorMssgExtract(error);
        if (msg == '403 Forbidden') {
          return 'Unauthorized';
        } else {
          return msg;
        }
      });
  } else {
    return 'Wrong suffix';
  }
}

function fileToString(file) {
  const extension = file.name.split('.').pop();
  if (extension === 'csv') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  } else if (extension === 'xlsx') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        resolve(jsonData);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  } else {
    return '';
  }
}

function getExtention(file) {
  return file.name.split('.').pop();
}
