import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import InfoData from '../InfoData';

export default function YearTermEditor({
  year,
  term,
  yearLabel,
  termLabel,
  yearChange,
  termChange,
}) {
  const [editorYear, setEditorYear] = React.useState(dayjs(year.toString()));
  const [editorTerm, setEditorTerm] = React.useState(term);

  return (
    <div style={{ paddingTop: '6px' }}>
      <FormControl sx={{ mb: 1, mr: 1, minWidth: 140 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year']}
            label={yearLabel}
            value={editorYear}
            onChange={(newValue) => {
              setEditorYear(newValue);
              yearChange(newValue.year());
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl sx={{ mb: 1, minWidth: 140 }}>
        <InputLabel htmlFor="native-select">{termLabel}</InputLabel>
        <Select
          native
          value={editorTerm}
          id="native-select"
          label={termLabel}
          onChange={(e) => {
            setEditorTerm(e.target.value);
            termChange(e.target.value);
          }}
        >
          {InfoData.termOptions.map((opt) => (
            <option value={opt.value} key={opt.value}>
              {' '}
              {opt.value}{' '}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
