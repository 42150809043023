import React, { useEffect, useState } from 'react';
import { Button, LinearProgress } from '@mui/material';
import { Download } from '@mui/icons-material';
import { generatePdf } from '../utils/pdfTools';
import useSelectionByStuId from '../api/hooks/useSelectionByStuId';
import useCrtfByStuId from '../api/hooks/useCrtfByStuId';

function DownloadPdFButton({ studentData, variantConfig, colorConfig }) {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data: selectionData, isLoading: isLoadingA } = useSelectionByStuId(
    shouldFetch ? studentData.id : null
  );
  const { data: crtfData, isLoading: isLoadingB } = useCrtfByStuId(
    shouldFetch ? studentData.id : null
  );

  useEffect(() => {
    if (selectionData && crtfData) {
      generatePdf(selectionData, crtfData, studentData);
      setShouldFetch(false);
    }
  }, [selectionData, crtfData, studentData]);

  if (isLoadingA || isLoadingB) {
    return <LinearProgress />;
  }
  const handleClick = () => {
    setShouldFetch(true);
  };

  return (
    <Button
      variant={variantConfig || 'contained'}
      color={colorConfig || 'secondary'}
      onClick={handleClick}
    >
      <Download sx={{ mr: 0.5 }} />
      POS
    </Button>
  );
}

export default DownloadPdFButton;
