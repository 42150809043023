import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function ErrorPage({ errorCode }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Typography variant="h1" color="error" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="h4" color="textSecondary" gutterBottom>
        {errorCode ? `Error ${errorCode}` : 'An error occurred'}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        Something went wrong. Please try again later.
      </Typography>
      <Button component={Link} to="/admin" variant="contained" color="primary">
        Go to Home
      </Button>
    </Box>
  );
}

export default ErrorPage;
