import React, { useEffect, useState } from 'react';
import { DialogContentText, TextField, Typography } from '@mui/material';
import { CURRENT } from '../../../../ENV';
import YearTermEditor from '../YearTermEditor';

export default function EnrollStatusNoteForm({ initNoteObj, setInitNoteObj }) {
  const defaultYear = CURRENT.YEAR;
  const defaultTerm = CURRENT.TERM;

  const initTitle = initNoteObj.title || initNoteObj.notes;
  const initYear1 = initTitle ? initTitle.split(' ')[0] : defaultYear;
  const initTerm1 = initTitle ? initTitle.split(' ')[1] : defaultTerm;
  const initYear2 = initTitle
    ? initTitle.split(' ')[3] || defaultYear
    : defaultYear;
  const initTerm2 = initTitle
    ? initTitle.split(' ')[4] || defaultTerm
    : defaultTerm;

  const [startYear, setStartYear] = useState(initYear1);
  const [startTerm, setStartTerm] = useState(initTerm1);
  const [endYear, setEndYear] = useState(initYear2);
  const [endTerm, setEndTerm] = useState(initTerm2);

  const setEnrollNoteTitle = () => {
    let newTitle = '';
    switch (initNoteObj.category) {
      case 'Absent':
        newTitle = `${startYear} ${startTerm}`;
        break;
      case 'Probation':
        newTitle = `${startYear} ${startTerm}`;
        break;
      case 'Withdraw':
        newTitle = `${startYear} ${startTerm}`;
        break;
      case 'Discontinue':
        newTitle = `${startYear} ${startTerm}`;
        break;
      case 'Deferral':
        newTitle = `${startYear} ${startTerm} - ${endYear} ${endTerm}`;
        break;
      default:
        break;
    }
    setInitNoteObj({ ...initNoteObj, title: newTitle });
  };

  useEffect(() => {
    setEnrollNoteTitle();
  }, [startYear, startTerm, endYear, endTerm]);

  const subTitleRender = (category) => {
    switch (category) {
      case 'Absent':
        return <DialogContentText>LOA until</DialogContentText>;
      default:
        return <></>;
    }
  };

  return (
    <>
      <DialogContentText>
        Please add the detail information for {initNoteObj.category} Note
      </DialogContentText>
      {subTitleRender(initNoteObj.category)}
      {initNoteObj.category === 'Absent' ||
      initNoteObj.category === 'Withdraw' ||
      initNoteObj.category === 'Probation' ||
      initNoteObj.category === 'Discontinue' ? (
        <YearTermEditor
          year={startYear}
          term={startTerm}
          yearLable="Year"
          termLable="Term"
          yearChange={(newYear) => {
            setStartYear(newYear);
          }}
          termChange={(newTerm) => {
            setStartTerm(newTerm);
          }}
        />
      ) : (
        <div>
          <Typography variant="h6">From</Typography>
          <YearTermEditor
            year={startYear}
            term={startTerm}
            yearLable="Year"
            termLable="Term"
            yearChange={(newYear) => {
              setStartYear(newYear);
            }}
            termChange={(newTerm) => {
              setStartTerm(newTerm);
            }}
          />
          <Typography variant="h6">To</Typography>
          <YearTermEditor
            year={endYear}
            term={endTerm}
            yearLable="Year"
            termLable="Term"
            yearChange={(newYear) => {
              setEndYear(newYear);
            }}
            termChange={(newTerm) => {
              setEndTerm(newTerm);
            }}
          />
        </div>
      )}
    </>
  );
}
