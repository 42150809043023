import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import MainCard from '../../../../components/MainCard';
import CertificationRow from './CertificationRow';
import useCertifProgInfo from '../../../../api/hooks/useCertifProgInfo';

function CertificationPanel({ optionData, index, certificationNotes }) {
  const { data, isLoading, isError } = useCertifProgInfo();

  if (isLoading || isError) {
    return <div>Loading...</div>;
  }
  return (
    <MainCard title="Certification">
      <Table>
        <TableHead>
          <TableRow>
            {/*<TableCell align="center">Check</TableCell>*/}
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Mark</TableCell>
            <TableCell align="center">Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <CertificationRow
              key={item.id}
              id={item.id}
              title={item.full_name}
              optionData={optionData}
              certificationNote={certificationNotes[item.full_name]}
            />
          ))}
        </TableBody>
      </Table>
    </MainCard>
  );
}

export default CertificationPanel;
