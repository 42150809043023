import React from 'react';
import * as AntIcon from '@ant-design/icons';

const NavBarData = [
  {
    authority: ['Faculty'],
    type: 'Item',
    title: 'Student List',
    path: '/faculty',
    icon: <AntIcon.UnorderedListOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff'],
    type: 'Item',
    title: 'Student List',
    path: '/admin',
    icon: <AntIcon.UnorderedListOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Dashboard',
    path: '/dashboard',
    icon: <AntIcon.DashboardOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Alumni Dashboard',
    path: '/alumni-dashboard',
    icon: <AntIcon.DashboardOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Advisor Dashboard',
    path: '/advisor-dashboard',
    icon: <AntIcon.DashboardOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Divider',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Student Report',
    path: '/student-report',
    icon: <AntIcon.LineChartOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Requirement Report',
    path: '/requirement-report',
    icon: <AntIcon.CheckSquareOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Graduation Report',
    path: '/graduation-report',
    icon: <AntIcon.ApartmentOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Cohort View',
    path: '/cohort-view',
    icon: <AntIcon.TeamOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Grade Report',
    path: '/grade-report',
    icon: <AntIcon.LineChartOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Divider',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Probation',
    path: '/probation-report',
    icon: <AntIcon.ExperimentOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Graduated',
    path: '/graduated-report',
    icon: <AntIcon.AuditOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Deferral',
    path: '/deferral-report',
    icon: <AntIcon.PauseCircleOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Withdraw',
    path: '/withdraw-report',
    icon: <AntIcon.MinusCircleOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Discontinued',
    path: '/discontinued-report',
    icon: <AntIcon.DisconnectOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Absent',
    path: '/absent-report',
    icon: <AntIcon.EyeInvisibleOutlined />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Faculty'],
    type: 'Item',
    title: 'Potential Discontinued',
    path: '/potential-discontinued-report',
    icon: <AntIcon.DisconnectOutlined />,
    cName: 'nav-text',
  },
];

export default NavBarData;
