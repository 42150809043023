import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Grid, Typography, Box, TextField, MenuItem } from '@mui/material';
import { calculateTermValue } from '../../../utils/sortByTerm';
import MainCard from '../../../components/MainCard';

function StudentCountTideSlide({
  data,
  appearedSemester,
  title,
  filterType,
  defaultStartTerm,
  defaultEndTerm,
}) {
  data = data.reduce((result, item) => {
    if (item.type === filterType) {
      result.push(item);
    }
    return result;
  }, []);
  const [startTerm, setStartTerm] = useState(defaultStartTerm);
  const [endTerm, setEndTerm] = useState(defaultEndTerm);
  const [filData, setFilData] = useState(data);

  useEffect(() => {
    const lowerBound = calculateTermValue(startTerm);
    const upperBound = calculateTermValue(endTerm);
    const temp = data.filter((obj) => {
      const currValue = calculateTermValue(obj.term);
      return currValue >= lowerBound && currValue <= upperBound;
    });
    setFilData(temp);
  }, [startTerm, endTerm]);

  const config = {
    appendPadding: 20,
    data: filData,
    // isStack: false,
    isGroup: true,
    height: 490,
    autoFit: true,
    smooth: true,
    xField: 'term',
    yField: 'value',
    seriesField: 'studentType',
    legend: {
      offsetX: 30,
      offsetY: -5,
      fontSize: 5,
    },
    tooltip: {
      showTitle: true,
    },
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid container alignItems="center" justifyContent="space-between">
        {/* Chart Title */}
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>

        {/* Term Button Group */}
        <Grid item>
          <TextField
            id="standard-select-currency"
            size="small"
            select
            value={startTerm}
            onChange={(e) => setStartTerm(e.target.value)}
            sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
          >
            {appearedSemester.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="standard-select-currency"
            size="small"
            select
            value={endTerm}
            onChange={(e) => setEndTerm(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                py: 0.5,
                fontSize: '0.875rem',
              },
              ml: 1,
            }}
          >
            {appearedSemester.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Chart */}
      <MainCard sx={{ mt: 2 }} content={false}>
        <Box sx={{ pl: 1, pr: 1, pt: 2 }}>
          <div id="chart">
            <Column {...config} />
          </div>
        </Box>
      </MainCard>
    </Grid>
  );
}

export default StudentCountTideSlide;
