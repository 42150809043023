import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import toast from 'react-hot-toast';
import NotesServices from '../../../../api/services/notesServices';
import RichTextWithFiles from './RichTextWithFiles';
import { EnrollStatusNeedNote } from '../../../../ENV';
import EnrollStatusNoteForm from './EnrollStatusNoteForm';
import TrackNoteForm from './TrackNoteForm';
import GeneralNoteForm from './GeneralNoteForm';

export default function NotePopup({
  studentObj,
  getStudentNotes,
  showNotePopup,
  setShowNotePopup,
  initNoteObj,
  setInitNoteObj,
  isNoteUpdate,
  setIsNoteUpdate,
}) {
  const student = studentObj;
  const initNote = {
    student_id: student.id,
    category: 'Other',
    notes: '',
    title: '',
    attachments: [],
  };

  const getRichNoteString = (note) => {
    if (!note) {
      return JSON.stringify([{ type: 'paragraph', children: [{ text: '' }] }]);
    }
    try {
      JSON.parse(note);
      return note;
    } catch (e) {
      // Split string by line breaker
      const paragraphs = note.split(/\r?\n|\r|\n/g);
      const noteResult = [];
      paragraphs.forEach((paragraph) => {
        noteResult.push({ type: 'paragraph', children: [{ text: paragraph }] });
      });
      return JSON.stringify(noteResult);
    }
  };

  const [notesInput, setNotesInput] = useState(getRichNoteString(''));
  const [attachList, setAttachList] = useState([]);

  useEffect(() => {
    setNotesInput(getRichNoteString(initNoteObj.notes));
    setAttachList(initNoteObj.attachments || []);
  }, [initNoteObj]);

  const fabStyle = {
    margin: 0,
    top: 100,
    right: 30,
    bottom: 'auto',
    left: 'auto',
    zIndex: 100,
    position: 'absolute',
  };

  const handleToOpenEditor = () => {
    setShowNotePopup(true);
  };

  const handleToCloseEditor = () => {
    setShowNotePopup(false);
    setIsNoteUpdate(false);
    setInitNoteObj(initNote);
  };

  const handleToSaveNote = async () => {
    if (initNoteObj.title) {
      const newNoteObj = {
        ...initNoteObj,
        notes: notesInput,
      };
      if (isNoteUpdate) {
        try {
          await NotesServices.UpdateNotesAndAttachments(newNoteObj, attachList);
          toast.success('Note has been successfully updated!');
        } catch (e) {
          toast.error('Note update failed: ', e);
        }
      } else {
        try {
          await NotesServices.SaveNotesAndAttachments(newNoteObj, attachList);
          toast.success('Note has been successfully saved!');
        } catch (e) {
          toast.error('Note submit failed: ', e);
        }
      }
      setShowNotePopup(false);
      setInitNoteObj(initNote);
      await getStudentNotes();
    } else {
      toast.error('Title is required!');
    }
  };

  const getNoteForm = () => {
    if (EnrollStatusNeedNote.includes(initNoteObj.category)) {
      return (
        <EnrollStatusNoteForm
          initNoteObj={initNoteObj}
          setInitNoteObj={setInitNoteObj}
        />
      );
    }
    if (initNoteObj.category === 'Track Change') {
      return (
        <TrackNoteForm
          initNoteObj={initNoteObj}
          setInitNoteObj={setInitNoteObj}
        />
      );
    }
    return (
      <GeneralNoteForm
        initNoteObj={initNoteObj}
        setInitNoteObj={setInitNoteObj}
      />
    );
  };

  return (
    <div>
      <ButtonGroup size="large" style={fabStyle}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleToOpenEditor}
        >
          <Add sx={{ mr: 0.5 }} />
          New Notes{' '}
        </Button>
      </ButtonGroup>
      <Dialog
        open={showNotePopup}
        onClose={handleToCloseEditor}
        PaperProps={{
          sx: {
            width: '80%',
          },
        }}
      >
        {initNoteObj.notes && <DialogTitle>Edit Note for Student</DialogTitle>}
        {!initNoteObj.notes && <DialogTitle>New Note for Student</DialogTitle>}
        <DialogContent style={{ paddingTop: '8px' }}>
          {getNoteForm()}
          <RichTextWithFiles
            notesInput={notesInput}
            setNotesInput={setNotesInput}
            attachList={attachList}
            setAttachList={setAttachList}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToCloseEditor}>Cancel</Button>
          <Button variant="contained" onClick={handleToSaveNote}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
