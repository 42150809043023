import React from 'react';
import { Button, Figure } from 'react-bootstrap';
import {
  StyledLayout,
  StyledLeftContainer,
  StyledRightContainer,
  StyledRow,
  StyledTwoColumnRow,
} from '../StyledComponents';
import avatar from '../default_avatar.png';
import DownloadPdFButton from '../../../components/DownloadPdFButton';

export default function PersonalInfo({ history, studentObj }) {
  const student = studentObj;

  function toTrackName(track) {
    switch (track) {
      case 'HI':
        return 'Health Informatics';
      case 'HSM':
        return 'Healthcare Supervision and Management';
      case 'RHIA':
        return 'Registered Health Information Administrator';
      case 'DS':
        return 'Data Science';
      default:
        return '';
    }
  }

  function handleGoToPOS(id) {
    const path = `/CoursePlan/:${id}`;
    history.push({
      pathname: '/CoursePlan',
      search: `${id}`,
    });
  }

  return (
    <StyledLayout hidden={student.id <= 0}>
      <StyledLeftContainer>
        <Figure
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Figure.Image width="40%" height="40%" src={avatar} />
        </Figure>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledRow>
          <div>{student.name}</div>
        </StyledRow>
        <StyledTwoColumnRow>
          <div>PEOPLESOFT ID : {student.peoplesoft_id}</div>
          <div>
            STATUS : {student.status === 'FT' ? 'Full Time' : 'Part Time'}
          </div>
        </StyledTwoColumnRow>
        <StyledTwoColumnRow>
          <div>TYPE : {student.type}</div>
          <div>TRACK : {toTrackName(student.track)}</div>
        </StyledTwoColumnRow>
        <StyledTwoColumnRow>
          <div>
            START :{' '}
            {student.start_term && student.start_year && student.start_year > 0
              ? `${student.start_term} ${student.start_year}`
              : 'N/A'}
          </div>
          <div>
            EXPECTED GRADUATE :{' '}
            {student.expected_grad_term &&
            student.expected_grad_year &&
            student.expected_grad_year > 0
              ? `${student.expected_grad_term} ${student.expected_grad_year}`
              : 'N/A'}
          </div>
        </StyledTwoColumnRow>
        <StyledRow>
          <Button
            variant="secondary"
            className="me-3"
            onClick={() => {
              handleGoToPOS(student.id);
            }}
          >
            Create POS
          </Button>
          {student.id > 0 ? <DownloadPdFButton studentData={student} /> : ''}
        </StyledRow>
      </StyledRightContainer>
    </StyledLayout>
  );
}
