import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { getYear } from 'date-fns';
import { isEmpty } from '../../utils';

function YearDropDown({ selectedYear, setYear, setTerm, setDay, setTime }) {
  const handleChange = (date) => {
    setTime('');
    setDay('');
    setTerm('');
    setYear(isEmpty(date) ? null : getYear(date).toString());
  };

  return (
    <DatePicker
      className="form-control"
      selected={selectedYear ? new Date(selectedYear, 1) : null}
      onChange={handleChange}
      yearItemNumber={10}
      dateFormat="yyyy"
      showYearPicker
      isClearable
      closeOnScroll
    />
  );
}

export default YearDropDown;
