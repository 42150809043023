import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Logo from '../Logo';
import { userStatus } from '../../api/api';

// const SideDrawerHeadStyled = styled(Box, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   ...theme.mixins.toolbar,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: open ? 'flex-start' : 'center',
//   paddingLeft: theme.spacing(open ? 3 : 0),
// }));

const SideDrawerHeadStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// ==============================|| DRAWER HEADER ||============================== //

function SideDrawerHead({ open }) {
  const theme = useTheme();
  // eslint-disable-next-line consistent-return
  const getMainPage = () => {
    const role = userStatus.userRole;
    switch (role) {
      case 'Staff':
        return '/admin';
      case 'Faculty':
        return '/faculty';
      default:
        return '/empty';
    }
  };
  return (
    <SideDrawerHeadStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Logo to={getMainPage()} />
        {/*@TODO: Chip Set up version number*/}
        {/*<Chip*/}
        {/*  // label={process.env.REACT_APP_VERSION}*/}
        {/*  label="1.0.0"*/}
        {/*  size="small"*/}
        {/*  sx={{*/}
        {/*    height: 16,*/}
        {/*    width: 50,*/}
        {/*    '& .MuiChip-label': { fontSize: '0.625rem' },*/}
        {/*  }}*/}
        {/*/>*/}
      </Stack>
      {/*<ChevronLeftIcon />*/}
    </SideDrawerHeadStyled>
  );
}

export default SideDrawerHead;
