import React from 'react';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
} from '@mui/material';
import InfoData from '../../InfoData';

export default function GeneralNoteForm({ initNoteObj, setInitNoteObj }) {
  const selectorStyle = {
    width: '40%',
    marginBottom: '15px',
  };

  const handleDropDown = (e) => {
    setInitNoteObj({ ...initNoteObj, category: e.target.value });
  };

  return (
    <>
      <FormControl style={selectorStyle}>
        <InputLabel id="notes-category">Category</InputLabel>
        <Select
          labelId="notes-category"
          value={initNoteObj.category}
          label="category"
          onChange={handleDropDown}
          defaultValue={initNoteObj.category}
        >
          {InfoData.noteOptions.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {' '}
              {opt.value}{' '}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        required
        label="Title"
        value={initNoteObj.title}
        style={{ marginLeft: '15px', marginBottom: '15px', width: '50%' }}
        placeholder="Add Title"
        onChange={(e) =>
          setInitNoteObj({ ...initNoteObj, title: e.target.value })
        }
      />
    </>
  );
}
