import React from 'react';
import useSWR from 'swr';
import _ from 'underscore';
import { userStatus } from '../api';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import { sortByTerm } from '../../utils/sortByTerm';
import studentServices from '../services/studentServices';

export default function useStuListByAdv(
  role = userStatus.userRole,
  advisorName = userStatus.userName
) {
  const { data, error, isLoading } = useSWR(
    [role, advisorName],
    studentServices.getAdviseeByAdvisor
  );

  // Semester options appear in student array
  const appearedSemester = [];
  const appearedStartSemester = [];
  if (data) {
    sortByTerm(data, 'DESC', 'concatGradTerm').forEach((item) => {
      if (!_.contains(appearedSemester, item.concatGradTerm)) {
        appearedSemester.push(item.concatGradTerm);
      }
    });

    sortByTerm(data, 'DESC', 'concatStartTerm').forEach((item) => {
      if (!_.contains(appearedStartSemester, item.concatStartTerm)) {
        appearedStartSemester.push(item.concatStartTerm);
      }
    });
  }

  return {
    studentList: data,
    appearedSemester,
    appearedStartSemester,
    isLoading,
    isError: error ? errorMssgExtract(error) : error,
  };
}
