import useSWR from 'swr';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import { API } from '../api';

const fetcher = (url) => API.post(url, {}).then((res) => res.data);

export default function useChartStat(url) {
  const { data, isLoading, error } = useSWR(url, fetcher);

  return {
    data,
    isLoading,
    isError: error ? errorMssgExtract(error) : error,
  };
}
