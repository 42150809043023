import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
// material-ui
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Skeleton,
} from '@mui/material';
import MainCard from '../../../components/MainCard';
import useChartStat from '../../../api/hooks/useChartStat';
import { CURRENT } from '../../../ENV';

function CourseEnrollCountChart({ appearedSemester }) {
  const { data: courEnrollData, isLoading } = useChartStat(
    '/students-courses/getCourseEnrollStat'
  );

  const [graphTerm, setGraphTerm] = useState(`${CURRENT.YEAR} ${CURRENT.TERM}`);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      let count = 0;
      courEnrollData[graphTerm].forEach((obj) => {
        count += obj.value;
      });
      setTotalCount(count);
    }
  }, [courEnrollData, graphTerm, isLoading]);

  if (isLoading) {
    return <Skeleton variant="rectangular" width={210} height={60} />;
  }

  const config = {
    appendPadding: 20,
    data: courEnrollData[graphTerm],
    xField: 'course',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.8,
      },
    },
    meta: {
      type: {
        alias: 'Course ID',
      },
      sales: {
        alias: 'Enroll Count',
      },
    },
    // minColumnWidth: 40,
    maxColumnWidth: 100,
  };
  // return <Column {...config} />;

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Course Enrollment Count</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="standard-select-currency"
            size="small"
            select
            value={graphTerm}
            onChange={(e) => setGraphTerm(e.target.value)}
            sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
          >
            {appearedSemester.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <MainCard content={false} sx={{ mt: 1.5 }}>
        {/* <Box sx={{ p: 3, pb: 1 }}>
           <Typography variant="h5" color="textSecondary">
             Total Student Number : {totalCount}
           </Typography>
         </Box> */}
        <Box sx={{ p: 3, pb: 1 }}>
          {/*<CourseEnrollmentCountChart slot={slot} />*/}
          <Column {...config} />
        </Box>
      </MainCard>
    </Grid>
  );
}

export default CourseEnrollCountChart;
