import React from 'react';
import { Modal, Box, Typography, Button, Divider, Stack } from '@mui/material';
import { Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid grey.100',
  boxShadow: 10,
  borderRadius: 2,
  p: 4,
  flexDirection: 'column',
  display: 'flex',
  flexWrap: 'wrap',
};

export default function CourseCreditBackdrop({
  open,
  handleClose,
  handleSubmitFunction,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { credit: 3 } });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={modalStyle}
        component="form"
        onSubmit={handleSubmit(handleSubmitFunction)}
      >
        <Typography variant="h5" id="modal-title">
          Choose dynamic credit
        </Typography>
        <Typography variant="caption">
          Please select the corresponding credit for the course, and make sure
          you save the changes to be reflected on PDF
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={1.5}>
          <Typography component="label" variant="h6">
            Credit
          </Typography>
          <Controller
            name="credit"
            control={control}
            render={({ field: { defaultValue, ...fieldProps } }) => (
              <Input
                {...fieldProps}
                label="Credit"
                type="select"
                defaultValue={defaultValue}
                placeholder="Select"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Input>
            )}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
