import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ListItemIcon } from '@mui/material';
import NavBarData from './NavBarData';
import { drawerWidth } from '../../ENV';
import SideDrawerHead from './SideDrawerHead';

const openedMixin = (theme) => ({
  width: drawerWidth,
  // borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: 'none',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

// TODO: Add Icon ahead of each menu item
export default function SideDrawer({ open }) {
  const location = useLocation();
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );
  const role = authenticationStatus.authenticationRole;
  const history = useHistory();

  const navigateTo = (url) => {
    history.push(url);
  };

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
      aria-label="side-drawer"
    >
      <Drawer variant="permanent" open={open}>
        <SideDrawerHead open={open} />
        <Divider />
        {NavBarData.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={i} disablePadding sx={{ display: 'block' }}>
            {' '}
            {
              // eslint-disable-next-line no-nested-ternary
              item.authority.includes(role) && item.type === 'Item' ? (
                <ListItemButton
                  selected={location.pathname === item.path}
                  key={item.path}
                  className={item.cName}
                  onClick={() => {
                    navigateTo(item.path);
                  }}
                  sx={{
                    minHeight: 55,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{
                      opacity: open ? 1 : 0,
                      pt: 0.5,
                      ml: 1.5,
                    }}
                  />
                </ListItemButton>
              ) : item.authority.includes(role) && item.type === 'Divider' ? (
                <Divider variant="middle" sx={{ my: 1 }} />
              ) : (
                <></>
              )
            }
          </ListItem>
        ))}
      </Drawer>
    </Box>
  );
}
