import React, { useMemo } from 'react';
import {
  Typography,
  Grid,
  LinearProgress,
  Button,
  ButtonGroup,
  Chip,
  Tooltip,
} from '@mui/material';
import { Add, Check, Close, CrisisAlert } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import DownloadPdFButton from '../../components/DownloadPdFButton';
import MainCard from '../../components/MainCard';
import StudentGrid from '../../components/StudentGrid';
import { ENROLLSTATUS } from '../../ENV';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 25,
  bottom: 20,
  left: 'auto',
  zIndex: 1500,
  position: 'absolute',
};

// ==============================|| PAGE / RENDER ||============================== //

export default function Faculty() {
  // const { studentList, isLoading, isError } = useMemo(
  //   () => useStuListByAdv,
  //   []
  // );

  const { studentList, isLoading, isError } = useStuListByAdv();
  const history = useHistory();

  if (isLoading || isError) {
    return <LinearProgress />;
  }

  const mutateData = () =>
    studentList.filter(
      (item) =>
        item.enrollment_status === ENROLLSTATUS.ACTIVE ||
        item.enrollment_status === ENROLLSTATUS.PROBATION
    );

  const handleToStudentInfo = (id) => {
    const path = `studentinfo/:${id}`;
    history.push(path);
  };

  const handleToPOS = (id) => {
    history.push({
      pathname: '/CoursePlan',
      search: `${id}`,
    });
  };

  const handleCellClick = (params) => {
    switch (params.field) {
      case 'name':
        handleToStudentInfo(params.id);
        break;
      default:
    }
  };

  const renderButton = (params) => (
    // console.log(params);
    <ButtonGroup disableElevation variant="contained" size="medium">
      <Button onClick={() => handleToPOS(params.id)}>View POS</Button>
      <DownloadPdFButton studentData={params.row} />
    </ButtonGroup>
  );

  const renderAdminStatus = (params) => {
    const score = params.row.adminStatus;
    switch (score) {
      case 0:
        return (
          <Tooltip title="Lacking of all 3">
            <Chip
              label="0/3"
              color="error"
              icon={<Close />}
              sx={{ width: '80%', height: '70%' }}
            />
          </Tooltip>
        );
      case 3:
        return (
          <Tooltip title="Satisfied all of them">
            <Chip
              label="3/3"
              color="success"
              // variant="outlined"
              icon={<Check />}
              sx={{ width: '80%', height: '70%' }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Missing 1 or 2 out of 3">
            <Chip
              label={`${score}/3`}
              color="warning"
              icon={<CrisisAlert />}
              sx={{ width: '80%', height: '70%' }}
            />
          </Tooltip>
        );
    }
  };

  const headCells = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'track',
      headerName: 'Track',
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'concatStartTerm',
      headerName: 'Start',
      width: 170,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'concatGradTerm',
      headerName: 'Expt Grad',
      width: 170,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'adminStatus',
      headerName: 'Admin Status',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: renderAdminStatus,
      disableClickEventBubbling: true,
    },
    {
      field: 'lastModifiedAt',
      headerName: 'Last modified at',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      type: 'dateTime',
      valueGetter: ({ value, id }) =>
        value && new Date(new Date(value).toLocaleString()),
      disableClickEventBubbling: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 250,
      renderCell: renderButton,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <ButtonGroup size="large" style={fabStyle}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleToStudentInfo(0)}
        >
          <Add sx={{ mr: 0.5 }} />
          New Student{' '}
        </Button>
      </ButtonGroup>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Student List</Typography>
      </Grid>
      <Grid item xs={12}>
        <MainCard sx={{ mb: 5 }}>
          <StudentGrid
            rows={mutateData()}
            columns={headCells}
            onCellClick={handleCellClick}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
}
