import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export default function View({ url }) {
  return (
    <Button
      href={url}
      target="_blank"
      aria-label="view"
      style={{ margin: '5px' }}
    >
      Open
    </Button>
  );
}
