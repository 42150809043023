import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { ENROLLSTATUS } from '../../ENV';

export default function ProbationReport() {
  const { studentList, appearedSemester, isLoading, isError } =
    useStuListByAdv();

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const mutateData = () => {
    let filtratedData = studentList.filter(
      (item) => item.enrollment_status === ENROLLSTATUS.PROBATION
    );
    return filtratedData;
  };

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Probation Students List</Typography>
        </Grid>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} />
        </MainCard>
      </Grid>
    </Grid>
  );
}
