import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import useChartStat from '../../api/hooks/useChartStat';
import MainCard from '../../components/MainCard';
import GradeTable from './charts/GradeTable';
import useSWR from 'swr';
import studentServices from '../../api/services/studentServices';
import { userStatus } from '../../api/api';
import { ENROLLSTATUS, CURRENT } from '../../ENV';
import EthnicityChart from './charts/EthnicityChart';
import ExpectedGradStuCount from './charts/ExpectedGradStuCount';

function Dashboard_Advisor() {
  const { studentList, isLoading: isLoadingA } = useStuListByAdv('Faculty');

  const { data: stuCountData, isLoading: isLoadingB } = useSWR(
    [
      [
        ENROLLSTATUS.ACTIVE,
        ENROLLSTATUS.PROBATION,
        ENROLLSTATUS.ABSENT,
        ENROLLSTATUS.GRADUATE,
      ],
      userStatus.userName,
    ],
    studentServices.getGradStudentCountByAdvisor
  );

  const { data: appearedSemester, isLoading: isLoadingC } = useChartStat(
    '/students-courses/getAppearedSemester'
  );

  if (isLoadingA || isLoadingB || isLoadingC) {
    return <LinearProgress />;
  }

  return (
    <Grid container sx={{ pt: 0 }} rowSpacing={4.5} columnSpacing={2.75}>
      {/* Title */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Advisor Dashboard</Typography>
      </Grid>
      {/* row 1 */}
      <ExpectedGradStuCount
        data={stuCountData}
        appearedSemester={appearedSemester}
        defaultStartTerm={CURRENT.YEAR - 1 + ' ' + CURRENT.TERM}
        defaultEndTerm={CURRENT.YEAR + 2 + ' ' + CURRENT.TERM}
      />
      <EthnicityChart appearedSemester={appearedSemester} />

      {/* row 2 */}
      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Student Info</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <GradeTable studentList={studentList} />
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default Dashboard_Advisor;
