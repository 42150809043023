import { API } from '../api';

const ProgramServices = {
  getOverallSemesters([startTerm, startYear, duration = 4]) {
    return API.post('/programs/getOverallSemesters', {
      student_start_term: startTerm,
      student_start_year: startYear,
      program_duration: duration,
    }).then((res) => res.data);
  },

  getCertificationPrograms() {
    return API.get('programs?type=Certification').then((res) => res.data);
  },

  getCrtfByStuId(studentId) {
    return API.post(`/programs/getCrtfByStuId`, {
      studentId,
    }).then((res) => res.data);
  },
};
export default ProgramServices;
