import { API } from '../api';

const SelectionServices = {
  getAppearedSemester() {
    return API.post(
      '/students-courses/students-courses/getAppearedSemester'
    ).then((res) => res.data);
  },

  getCourseEnrollStat() {
    return API.post('/students-courses/getCourseEnrollStat').then(
      (res) => res.data
    );
  },

  getSelectionByStuID(id) {
    return API.get(
      `/students-courses?_limit=-1&student_id=${id}&deactivate=false`
    ).then((res) => res.data);
  },

  getSelectedCoursesForStu(id) {
    return API.post('/students-courses/getSelectedCoursesForStudent', {
      studentId: id,
    }).then((res) => res.data);
  },

  validateTemplate() {
    return API.get('/templates/templateValidate').then((res) => res.data);
  },
};

export default SelectionServices;
