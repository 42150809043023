export const CONFIG = {
  PORTAL: process.env.REACT_APP_API_URL,
};

const getCurrentTerm = () => {
  const currentMonth = new Date().getMonth();
  switch (currentMonth) {
    case 0:
    case 1:
    case 2:
    case 3:
      return 'Spring';
    case 4:
    case 5:
    case 6:
    case 7:
      return 'Summer';
    case 8:
    case 9:
    case 10:
    case 11:
      return 'Fall';
    default:
      return 'Fall';
  }
};
export const CURRENT = {
  YEAR: new Date().getFullYear(),
  TERM: getCurrentTerm(),
};

export const ENROLLSTATUS = {
  ACTIVE: 'ACTIVE',
  PROBATION: 'PROBATION',
  ABSENT: 'ABSENT',
  GRADUATE: 'GRADUATE',
  DISCONTINUE: 'DISCONTINUE',
  DEFERRAL: 'DEFERRAL',
  WITHDRAW: 'WITHDRAW',
};
export const GRADEPOINTS = {
  'A+': 4.0,
  A: 4.0,
  'A-': 3.75,
  'B+': 3.25,
  B: 3.0,
  'B-': 2.75,
  'C+': 2.25,
  C: 2.0,
  'C-': 1.75,
  'D+': 1.25,
  D: 1.0,
  'D-': 0.75,
  F: 0.0,
};

export const EnrollStatusNeedNote = [
  'Absent',
  'Withdraw',
  'Discontinue',
  'Deferral',
  'Probation',
];

export const drawerWidth = 240;
