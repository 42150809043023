import React from 'react';
import { useHistory } from 'react-router-dom';
import StudentGrid from '../../components/StudentGrid';

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  // {
  //   field: 'id',
  //   headerName: 'ID',
  //   type: 'number',
  //   width: 100,
  //   headerAlign: 'center',
  //   align: 'center',
  // },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'track',
    headerName: 'Track',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatStartTerm',
    headerName: 'Start',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatGradTerm',
    headerName: 'Expt Grad',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'plan_available',
    headerName: 'POS',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    type: 'string',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
  // {
  //   field: 'enrollment_status',
  //   headerName: 'Enrollment',
  //   type: 'string',
  //   width: 150,
  //   headerAlign: 'center',
  //   align: 'center',
  // },
];

// ==============================|| STUDENT TABLE - TABLE / RENDER ||============================== //
export default function StudentTable({ studentList, headConfig = headCells }) {
  const history = useHistory();
  const handleToStudentInfo = (id) => {
    const path = `studentinfo/:${id}`;
    history.push(path);
  };

  const handleCellClick = (params) => {
    switch (params.field) {
      case 'name':
        handleToStudentInfo(params.id);
        break;
      default:
    }
  };

  return (
    <div>
      <StudentGrid
        rows={studentList}
        columns={headConfig}
        onCellClick={handleCellClick}
      />
    </div>
  );
}
