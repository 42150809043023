import React from 'react';
import { Accordion, Button, Table } from 'react-bootstrap';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ViewOrEditField from '../ViewOrEditField';
import ViewOrEditSelector from '../ViewOrEditSelector';
import InfoData from '../InfoData';
import ViewOrEditMultiSelector from '../ViewOrEditMultiSelector';

export default function GeneralInfo({
  studentObj,
  isDisabled,
  setIsDisabled1,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  update,
}) {
  const student = studentObj;
  const sessionId = 1;
  const yearParser = (target) => {
    if (student[target]) {
      // special case Date.parse(1970)=0 cause datepicker display issue
      if (Number(student[target]) === 1969) {
        return -10000000000;
      }
      return Date.parse(Number(student[target]) + 1);
    }
    return Date.parse('');
  };

  return (
    <Accordion
      defaultActiveKey={['0']}
      alwaysOpen
      style={{ marginTop: '20px' }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>General Information</Accordion.Header>
        <Accordion.Body>
          <Form>
            <FormGroup>
              <Table borderless>
                <tbody>
                  <tr>
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="First Name"
                      fieldValue={student.first_name}
                      isDisabled={isDisabled}
                      studentInfoKey="first_name"
                    />
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Middle Name"
                      fieldValue={student.middle_name}
                      isDisabled={isDisabled}
                      studentInfoKey="middle_name"
                    />
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Last Name"
                      fieldValue={student.last_name}
                      isDisabled={isDisabled}
                      studentInfoKey="last_name"
                    />
                  </tr>
                  <tr>
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Peoplesoft Id"
                      fieldValue={student.peoplesoft_id}
                      isDisabled={isDisabled}
                      studentInfoKey="peoplesoft_id"
                    />
                    <ViewOrEditSelector
                      updateInfo={update}
                      fieldName="Enrollment Status"
                      fieldValue={student.enrollment_status}
                      isDisabled={isDisabled}
                      studentInfoKey="enrollment_status"
                      selectOptions={
                        student.id > 0
                          ? InfoData.enrollmentStatusOptions
                          : InfoData.initEnrollmentStatusOptions
                      }
                    />
                  </tr>
                  <tr>
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="City / Town"
                      fieldValue={student.city}
                      isDisabled={isDisabled}
                      studentInfoKey="city"
                    />
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="State"
                      fieldValue={student.state}
                      isDisabled={isDisabled}
                      studentInfoKey="state"
                    />
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Zip Code"
                      fieldValue={student.zipcode}
                      isDisabled={isDisabled}
                      studentInfoKey="zipcode"
                    />
                  </tr>
                  <tr>
                    <ViewOrEditSelector
                      updateInfo={update}
                      fieldName="Gender"
                      fieldValue={student.gender}
                      isDisabled={isDisabled}
                      studentInfoKey="gender"
                      selectOptions={InfoData.genderOptions}
                    />
                    <ViewOrEditSelector
                      updateInfo={update}
                      fieldName="Ethnicity"
                      fieldValue={student.ethnicity}
                      isDisabled={isDisabled}
                      studentInfoKey="ethnicity"
                      selectOptions={InfoData.ethnicityOptions}
                    />
                    {/*<ViewOrEditMultiSelector*/}
                    {/*  updateInfo={update}*/}
                    {/*  fieldName="Race"*/}
                    {/*  fieldValue={student.race}*/}
                    {/*  isDisabled={isDisabled}*/}
                    {/*  studentInfoKey="race"*/}
                    {/*  selectOptions={InfoData.raceOptions}*/}
                    {/*/>*/}
                  </tr>
                  <tr>
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Email"
                      fieldValue={student.email}
                      isDisabled={isDisabled}
                      studentInfoKey="email"
                    />
                    <ViewOrEditField
                      updateInfo={update}
                      fieldName="Personal Email"
                      fieldValue={student.personal_email}
                      isDisabled={isDisabled}
                      studentInfoKey="personal_email"
                    />
                  </tr>
                  <tr>
                    <td>
                      <Label for="yearOfBirth">Year of Birth</Label>
                      <DatePicker
                        disabled={isDisabled}
                        customInput={<Input />}
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={10}
                        closeOnScroll
                        selected={yearParser('year_of_birth')}
                        onChange={(date) => {
                          if (date) {
                            update(date.getFullYear(), 'year_of_birth');
                          } else {
                            update(null, 'year_of_birth');
                          }
                        }}
                        placeholderText="Year of Birth"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Button
                className={
                  isDisabled && handleEditClick(sessionId)
                    ? 'col-1 me-3 btn-primary'
                    : 'col-1 me-3 btn-secondary'
                }
                onClick={() => {
                  if (!(isDisabled && handleEditClick(sessionId))) {
                    handleCancelClick(sessionId);
                  }
                  if (handleEditClick(sessionId)) {
                    setIsDisabled1(!isDisabled);
                  }
                }}
                hidden={student.id <= 0}
              >
                {' '}
                {isDisabled ? 'Edit ' : ' Cancel '}
              </Button>
              <Button
                className={
                  isDisabled
                    ? 'col-1 me-3 btn-secondary'
                    : 'col-1 me-3 btn-primary'
                }
                onClick={() => handleSaveClick(sessionId)}
                hidden={student.id <= 0}
              >
                {' '}
                Save{' '}
              </Button>
            </FormGroup>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
