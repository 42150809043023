import { API } from '../api';

const StudentServices = {
  getStudentCountTide() {
    return API.post('/students/getStudentCountTide', {}).then(
      (res) => res.data
    );
  },

  getGradStudentCountByAdvisor([arrEnrollStatus, advisorName]) {
    return API.post('/students/getGradStudentCountByAdvisor', {
      arrEnrollStatus: arrEnrollStatus,
      advisorName: advisorName,
    }).then((res) => res.data);
  },

  getEthnicityStudentCountByAdvisor([advisorName, selectTerm, currentTerm]) {
    return API.post('/students/getEthnicityStudentCountByAdvisor', {
      advisorName: advisorName,
      selectTerm: selectTerm,
      currentTerm: currentTerm,
    }).then((res) => res.data);
  },

  getAdviseeByAdvisor([role, advisorName]) {
    return API.post('/students/getAdviseeByAdvisor', {
      role,
      advisorName,
    }).then((res) => res.data);
  },

  addStudents(Pbody) {
    return API.post(`/students`, Pbody);
  },

  editStudents(Pbody, targetId) {
    return API.put(`/students/${targetId}`, Pbody);
  },

  importStudents(Pbody) {
    return API.post(`/students/importStudents`, { students: Pbody });
  },
};

export default StudentServices;
