import React, { useState } from 'react';
import { Accordion, Button, Table } from 'react-bootstrap';
import { Form, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ViewOrEditField from '../ViewOrEditField';

export default function Background({
  studentObj,
  isDisabled,
  setIsDisabled2,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  update,
}) {
  const student = studentObj;
  const sessionId = 2;
  const yearParser = (target) => {
    if (student[target]) {
      return Date.parse(Number(student[target]) + 1);
    }
    return Date.parse('');
  };

  return (
    <Accordion
      defaultActiveKey={['0']}
      alwaysOpen={student.id > 0}
      style={{ marginTop: '20px' }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Background</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Table borderless>
              <tbody>
                <tr>
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Current/Previous Company"
                    fieldValue={student.company}
                    isDisabled={isDisabled}
                    studentInfoKey="company"
                  />
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Position"
                    fieldValue={student.position}
                    isDisabled={isDisabled}
                    studentInfoKey="position"
                  />
                  <td>
                    <Label for="jobStartYear">Job Start Year</Label>
                    <DatePicker
                      disabled={isDisabled}
                      customInput={<Input />}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      closeOnScroll
                      selected={yearParser('job_start_year')}
                      onChange={(date) => {
                        if (date) {
                          update(
                            date.toLocaleDateString('en-GB', {
                              year: 'numeric',
                            }),
                            'job_start_year'
                          );
                        } else {
                          update(null, 'job_start_year');
                        }
                      }}
                      placeholderText="Job Start Year"
                    />
                  </td>
                </tr>
                <tr>
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Previous School Name"
                    fieldValue={student.prev_school}
                    isDisabled={isDisabled}
                    studentInfoKey="prev_school"
                  />
                  <ViewOrEditField
                    updateInfo={update}
                    fieldName="Previous Major"
                    fieldValue={student.undergrad_major}
                    isDisabled={isDisabled}
                    studentInfoKey="undergrad_major"
                  />
                  <td>
                    <Label for="jobStartYear">Previous Graduation Year</Label>
                    <DatePicker
                      disabled={isDisabled}
                      customInput={<Input />}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      closeOnScroll
                      selected={yearParser('prev_grad_year')}
                      onChange={(date) => {
                        if (date) {
                          update(
                            date.toLocaleDateString('en-GB', {
                              year: 'numeric',
                            }),
                            'prev_grad_year'
                          );
                        } else {
                          update(null, 'prev_grad_year');
                        }
                      }}
                      placeholderText="Previous Graduation Year"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Button
              className={
                isDisabled && handleEditClick(sessionId)
                  ? 'col-1 me-3 btn-primary'
                  : 'col-1 me-3 btn-secondary'
              }
              onClick={() => {
                if (!(isDisabled && handleEditClick(sessionId))) {
                  handleCancelClick(sessionId);
                }
                if (handleEditClick(sessionId)) {
                  setIsDisabled2(!isDisabled);
                }
              }}
              hidden={student.id <= 0}
            >
              {' '}
              {isDisabled ? 'Edit ' : ' Cancel '}
            </Button>
            <Button
              className={
                isDisabled
                  ? 'col-1 me-3 btn-secondary'
                  : 'col-1 me-3 btn-primary'
              }
              onClick={() => handleSaveClick(sessionId)}
              hidden={student.id <= 0}
            >
              {' '}
              Save{' '}
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
