import useSWR from 'swr';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import programServices from '../services/programServices';

export default function useCrtfByStuId(id) {
  const { data, error, isLoading } = useSWR(
    id ? [id, 'PROGCRTF'] : null,
    ([studentId]) => programServices.getCrtfByStuId(studentId)
  );

  return {
    data,
    isLoading,
    isError: error ? errorMssgExtract(error) : error,
  };
}
