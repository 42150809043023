import React, { useEffect, useRef } from 'react';

const useNonInitUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
};

export default useNonInitUpdateEffect;
