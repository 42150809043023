import React from 'react';
import { Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../components/MainCard';

export default function StudentInfoPanel({ student }) {
  const history = useHistory();
  const handleViewMore = () => {
    const path = `studentinfo/:${student.id}`;
    history.push(path);
  };

  return (
    <MainCard
      title="Student Profile Panel"
      hasActions
      buttonGroup={
        <Button variant="contained" onClick={handleViewMore}>
          View More
        </Button>
      }
    >
      <Typography color="text">
        <b>Name: </b>
        {student.name}
      </Typography>
      <Typography color="text">
        <b>Gender:</b> {student.gender}
      </Typography>
      <Typography color="text">
        <b>PeopleSoft ID:</b> {student.peoplesoft_id}
      </Typography>
      <Typography color="text">
        <b>Start Term:</b> {student.start_year} {student.start_term}
      </Typography>
      <Typography color="text">
        <b>Expected Grad Term:</b> {student.expected_grad_year}{' '}
        {student.expected_grad_term}
      </Typography>
      <Typography color="text">
        <b>Status:</b> {student.status}
      </Typography>
      <Typography color="text">
        <b>Type:</b> {student.type}
      </Typography>
      <Typography color="text">
        <b>Track:</b> {student.track}
      </Typography>
    </MainCard>
  );
}
