import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import axios from 'axios';

import MainCard from '../../../components/MainCard';
import store from '../../../store/store';
import { CONFIG, CURRENT } from '../../../ENV';
import { useSelector } from 'react-redux';

// chart options
const barChartOptions = {
  chart: {
    // height: 365,
    // width: 380,
    type: 'donut',
  },
  labels: [],
  dataLabels: {
    enabled: false,
  },
  colors: [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#546E7A',
    '#D4526E',
    '#8D5B4C',
    '#F86624',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
  ],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: true,
        },
      },
    },
  ],
  legend: {
    inverseOrder: true,
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: 15,
    offsetY: 10,
    // show: false,
  },
};

const initData = [54, 56];

// ==============================|| MONTHLY BAR CHART ||============================== //
function StuEthnicityChart({ appearedSemester }) {
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );
  const [options, setOptions] = useState(barChartOptions);
  const [series, setSeries] = useState(initData);
  const [totalNumber, setTotalNumber] = useState(100);
  const [graphTerm, setGraphTerm] = useState(`${CURRENT.YEAR} ${CURRENT.TERM}`);

  useEffect(async () => {
    const genderStat = await axios.post(
      `${CONFIG.PORTAL}/students/getEthnicityStat`,
      {
        selectTerm: graphTerm,
        currentTerm: `${CURRENT.YEAR} ${CURRENT.TERM}`,
      },
      {
        headers: {
          Authorization: `Bearer ${authenticationStatus.jwtToken}`,
        },
      }
    );
    setSeries(genderStat.data.data);
    setOptions((prevState) => ({
      ...prevState,
      labels: genderStat.data.label,
    }));
    setTotalNumber(() => {
      let total = 0;
      genderStat.data.data.forEach((value) => {
        total += value;
      });
      return total;
    });
  }, [graphTerm]);

  return (
    <Grid item xs={12} md={4} lg={4}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Ethnicity</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="standard-select-currency"
            size="small"
            select
            value={graphTerm}
            onChange={(e) => setGraphTerm(e.target.value)}
            sx={{ '& .MuiInputBase-input': { py: 0, fontSize: '0.875rem' } }}
          >
            {appearedSemester.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <MainCard sx={{ mt: 2 }} content={false}>
        <Box sx={{ p: 3, pb: 1 }}>
          <Stack spacing={2}>
            <Typography variant="h5" color="textSecondary">
              Total Student Number
            </Typography>
            <Typography variant="h2">{totalNumber}</Typography>
          </Stack>
        </Box>
        <Box sx={{ p: 1, pb: 5 }} id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height="351"
          />
        </Box>
      </MainCard>
    </Grid>
  );
}

export default StuEthnicityChart;
