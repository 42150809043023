import POS from '../assets/POS_Certification.json';

const splitTerm = (term, hasSpaceInMiddle) => {
  if (hasSpaceInMiddle) {
    return term.split(' ');
  }
  return [term.slice(0, 4), term.slice(4, term.length)];
};

const calculateTermValue = (semester, hasSpaceInMiddle = true) => {
  const [year, term] = splitTerm(semester, hasSpaceInMiddle);
  if (year === 'null' || term === 'null') {
    return -1;
  }
  let flag = 0;
  switch (term) {
    case 'Spring':
      flag = 1;
      break;
    case 'Summer':
      flag = 2;
      break;
    case 'Fall':
      flag = 3;
      break;
    default:
      flag = 0;
      break;
  }
  return year * 10 + flag;
};

const semesterConvertor = (year, term) => {
  if (!year || !term) {
    return -1;
  }

  let flag = 0;
  switch (term) {
    case 'Spring':
      flag = 1;
      break;
    case 'Summer':
      flag = 2;
      break;
    case 'Fall':
      flag = 3;
      break;
    default:
      flag = 0;
      break;
  }
  return year * 10 + flag;
};

/**
 * Description. (use to auto-calculate the last term)
 * @param arr
 * @returns {String} String last term
 */
const determineLastSemester = (arr) => {
  let lastScore = 0;
  let lastSemester = '';
  arr.forEach((item, index) => {
    let score = item.courseYear * 10;
    switch (item.courseTerm) {
      case 'Spring':
        score += 1;
        break;
      case 'Summer':
        score += 2;
        break;
      case 'Fall':
        score += 3;
        break;
      default:
        score += 0;
    }
    if (score > lastScore) {
      lastSemester = item.courseYear + item.courseTerm;
      lastScore = score;
    }
  });
  return lastSemester;
};

/**
 * Determine whether term is valid
 * @param {String} term
 * @returns {boolean} true if term is valid
 */
const isValidTerm = (term) =>
  term === 'Spring' || term === 'Summer' || term === 'Fall';

const isValidYear = (year) => {
  if (typeof year === 'string' && year.length > 0) {
    return true;
  }
  return typeof year === 'number' && year > 0;
};

const getCorrPOSRequire = (studentInfo, isCertificationTrack) => {
  const allTemplates = isCertificationTrack ? POS.Certification : POS.MSHI;
  const studentValue = semesterConvertor(
    studentInfo.template_year || studentInfo.start_year,
    studentInfo.template_term || studentInfo.start_term
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const period in allTemplates) {
    if (Object.prototype.hasOwnProperty.call(allTemplates, period)) {
      const lowerBound = period.split('-')[0];
      const upperBound = period.split('-')[1];
      const lowerValue = semesterConvertor(
        lowerBound.slice(0, 4),
        lowerBound.slice(4, lowerBound.length)
      );
      const upperValue = semesterConvertor(
        upperBound.slice(0, 4),
        upperBound.slice(4, upperBound.length)
      );
      if (studentValue >= lowerValue && studentValue <= upperValue) {
        if (isCertificationTrack) {
          return allTemplates[period][studentInfo.track];
        }
        return allTemplates[period][
          studentInfo.type ? studentInfo.type : 'ONCMP'
        ][studentInfo.track ? studentInfo.track : 'HI'];
      }
    }
  }
  return {};
};

export {
  calculateTermValue,
  semesterConvertor,
  determineLastSemester,
  isValidTerm,
  isValidYear,
  getCorrPOSRequire,
  splitTerm,
};
