import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import SideDrawer from '../components/NavBar/SideDrawer';
import Header from '../components/SiteHeader/Header';

// @TODO: Apply useMemo()

function MainLayout(props) {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(!matchDownMD);

  useEffect(() => {
    setOpen(!matchDownMD);
  }, [matchDownMD]);

  const handleDrawerOpenToggle = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        overflowY: 'scroll',
      }}
    >
      <CssBaseline />
      <SideDrawer open={open} handleDrawerOpenToggle={handleDrawerOpenToggle} />
      <Header open={open} handleDrawerOpenToggle={handleDrawerOpenToggle} />
      <Box
        component="main"
        sx={{
          width: '100%',
          flexgrow: 1,
          p: { xs: 2, sm: 3 },
          bgcolor: 'grey.50',
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default MainLayout;
