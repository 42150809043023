import React from 'react';
import { TextField } from '@mui/material';

export default function TrackNoteForm({ initNoteObj, setInitNoteObj }) {
  return (
    <>
      {/*<TextField*/}
      {/*  InputProps={{*/}
      {/*    readOnly: true,*/}
      {/*  }}*/}
      {/*  variant="outlined"*/}
      {/*  label="Category"*/}
      {/*  value={initNoteObj.category}*/}
      {/*/>*/}
      <TextField
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
        label="Title"
        style={{ marginBottom: '15px', width: '50%' }}
        value={initNoteObj.title}
      />
    </>
  );
}
