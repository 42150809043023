import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

function TermDropDown({
  courseAvailTerm,
  courseUnavailTerm,
  selectedTerm,
  setTerm,
  setDay,
  setTime,
}) {
  const handleChange = (event) => {
    const selection = event.target.value;
    if (selection.length > 0) {
      setTime('');
      setDay('');
      setTerm(selection);
    } else {
      setTime('');
      setDay('');
      setTerm('');
    }
  };

  return (
    <FormControl sx={{ minWidth: 100, minHeight: 40 }}>
      <Select
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        value={selectedTerm}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {courseUnavailTerm.map((item) => (
          <MenuItem value={item} key={item} sx={{ color: 'grey' }}>
            {item}
          </MenuItem>
        ))}
        {courseAvailTerm.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TermDropDown;
