import React, { useState } from 'react';
import { Column } from '@ant-design/plots';
import {
  Box,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import MainCard from '../../../components/MainCard';
import { CURRENT } from '../../../ENV';
import useChartStat from '../../../api/hooks/useChartStat';

function StuEnrollCountChart({ appearedSemester }) {
  const {
    data: stuEnrollData,
    isLoading,
    isError,
  } = useChartStat('/students-courses/getStuTypeEnrollStat');
  const [graphTerm, setGraphTerm] = useState(`${CURRENT.YEAR} ${CURRENT.TERM}`);

  if (isLoading || isError) {
    return <Skeleton variant="rectangular" width={477} height={60} />;
  }

  const config = {
    data: stuEnrollData[graphTerm],
    height: 477,
    isGroup: true,
    xField: 'day',
    yField: 'value',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      {/* Drop Down */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Online / Oncampus Count</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="standard-select-currency"
            size="small"
            select
            value={graphTerm}
            onChange={(e) => setGraphTerm(e.target.value)}
            sx={{ '& .MuiInputBase-input': { py: 0, fontSize: '0.875rem' } }}
          >
            {appearedSemester.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {/* Chart */}
      <MainCard sx={{ mt: 1 }}>
        <Box sx={{ p: 2 }}>
          <Column {...config} />
        </Box>
      </MainCard>
    </Grid>
  );
}

export default StuEnrollCountChart;
