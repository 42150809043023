import React, { useState } from 'react';
import { Label } from 'reactstrap';
import Switch from 'react-switch';

export default function ViewOrEditSlider({
  updateInfo,
  fieldName,
  fieldValue,
  isDisabled,
  studentInfoKey,
  isSliderTrue,
}) {
  const [checked, setChecked] = useState(fieldValue || false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    isSliderTrue(nextChecked, studentInfoKey);
    updateInfo(nextChecked, studentInfoKey);
  };

  return (
    <div style={{ marginRight: '80px' }}>
      <Label>{fieldName}</Label>
      <div>
        <Switch
          disabled={isDisabled}
          onChange={handleChange}
          checked={checked}
          className="react-switch"
        />
      </div>
    </div>
  );
}
