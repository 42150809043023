import React from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';

function SemesterDropDown(props) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="overline" textAlign="center" color="textSecondary">
        {props.title ? props.title : ''}
      </Typography>
      <TextField
        id="standard-select-currency"
        size="small"
        select
        value={props.value}
        onChange={props.onChange}
        sx={{
          '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' },
        }}
      >
        {props.appearedSemester.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}

export default SemesterDropDown;
