import axios from 'axios';
import { CONFIG } from '../../../ENV';

/**
 *
 * @param {Object} Pbody Post body
 * @param {function} actionsAfterThen function that will be executed after the then
 * @param {function} actionsAfterCatch functions that will be executed after the catch
 * @returns return the response of the request (success or error)
 */
export default async function UploadAlumniSurvey(
  formData, // Now expecting FormData
  actionsAfterThen,
  actionsAfterCatch
) {
  return axios
    .post(`${CONFIG.PORTAL}/alumni-survey/upload`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('authenticationStatus')).jwtToken
        }`,
        // No need to set 'Content-Type' as axios will handle it with FormData
      },
    })
    .then((resp) => {
      actionsAfterThen(resp);
      return resp;
    })
    .catch((error) => {
      actionsAfterCatch(error);
      return error;
    });
}
