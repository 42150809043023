import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, LinearProgress, Typography, Stack, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import toast from 'react-hot-toast';
import StyledDropzone from '../components/StyledDropzone.jsx';
import { errorMssgExtract } from '../../../utils/errorHandleTools.js';
import MainCard from '../../../components/MainCard.jsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import UploadAlumniSurvey from '../connectors/UploadAlumniSurvey.js';

export default function AdminImportSurvey() {
  const [files, setFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        setFiles({
          name: file.name,
          path: file.path,
          content: binaryStr, // File content in binary (if needed later)
          file: file, // Store the actual file object here
        });
      };
      reader.readAsBinaryString(file);
    });
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    onDrop,
  });

  const uploadDocument = async () => {
    setLoading(true);

    if (!files.file) {
      toast.error('Please select a file before uploading.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', files.file);

    try {
      await UploadAlumniSurvey(
        formData,
        (resp) => {
          setLoading(false);
          toast.success(`File uploaded successfully. Data has been saved.`);
          history.push('/alumni-dashboard'); // Redirect after success
        },
        (error) => {
          setLoading(false);
          toast.error(errorMssgExtract(error));
        }
      );
    } catch (error) {
      setLoading(false);
      toast.error('Error uploading document.');
    }
  };

  const backToPreviousPage = () => {
    history.push('/alumni-dashboard');
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Auto upload survey by file
        </Typography>
        <MainCard
          sx={{ p: 0 }}
          title={`Selected File: ${files.name}`}
          hasActions
          buttonGroup={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={backToPreviousPage}
              >
                <ArrowBackIcon sx={{ mr: 0.5 }} />
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={uploadDocument}
              >
                <ArrowUpwardIcon sx={{ mr: 0.5 }} />
                Submit file
              </Button>
            </Stack>
          }
        >
          <StyledDropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isFocused={isFocused}
            isDragActive={isDragActive}
            selectedFileTitle={files.name}
          />
        </MainCard>
      </div>
    </Box>
  );
}
