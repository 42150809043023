import React, { useCallback, useState, lazy, Suspense } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  ButtonGroup,
  Button,
  LinearProgress,
  Typography,
  Stack,
  Divider,
  Box,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Chip from '@mui/material/Chip';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import StyledDropzone from '../components/StyledDropzone';
import { errorMssgExtract } from '../../../utils/errorHandleTools';
import StudentGrid from '../../../components/StudentGrid';
import MainCard from '../../../components/MainCard';
import ConfirmDialog from '../../../components/ConfirmDialog';
import UploadGrades from '../connectors/UploadGrades';
import HeaderButtons from './components/HeaderButtons';
import TermAndYearSelectionModal from '../components/TermAndYearSelectionModal';

const headConfig = [
  { field: 'peoplesoftId', headerName: 'Peoplesoft ID', width: 150 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'courseId', headerName: 'Course ID', width: 150 },
  { field: 'grade', headerName: 'Grade', width: 100 },
  { field: 'previousGrade', headerName: 'Previous Grade', width: 100 },
  { field: 'result', headerName: 'Result', width: 250 },
  { field: 'advisor', headerName: 'Advisor', width: 150 },
  {
    field: 'isOutsideCourse',
    headerName: 'Outside Course',
    width: 150,
    type: 'boolean',
    renderCell: (params) =>
      params.value ? (
        <Chip label="Outside Course" size="small" color="success" />
      ) : (
        <></>
      ),
  },
  { field: 'session_message', headerName: 'Session Message', width: 250 },
];

const failedHeadConfig = [
  { field: 'peoplesoftId', headerName: 'Peoplesoft ID', width: 150 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'enrollmentStatus', headerName: 'Status', width: 150 },
  { field: 'courseId', headerName: 'Course ID', width: 150 },
  { field: 'grade', headerName: 'Grade', width: 100 },
  { field: 'previousGrade', headerName: 'Previous Grade', width: 100 },
  { field: 'result', headerName: 'Result', width: 250 },
  { field: 'advisor', headerName: 'Advisor', width: 150 },
];

const AdminImportGradesResultPage = React.lazy(() =>
  import('./pages/AdminImportGradesResultPage.jsx')
);

export default function AdminImportGrades() {
  // Initializer
  const [files, setFiles] = useState({});
  // Page Control
  const [uploadPageHidden, setUploadPageHidden] = useState(false);
  const [resultPageHidden, setResultPageHidden] = useState(true);
  const [previewPageHidden, setPreviewPageHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // Data store
  const [successImport, setSuccessImport] = useState([]);
  const [failImport, setFailImport] = useState([]);
  const [consistentImport, setConsistentImport] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [unmatchExcel, setUnmatchExcel] = useState('');
  const [matchExcel, setMatchExcel] = useState('');
  const [openTermAndYearModal, setOpenTermAndYearModal] = useState(false);
  const [termAndYear, setTermAndYear] = useState({});
  const [resultContent, setResultContent] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        setFiles({
          name: acceptedFiles[0].name,
          path: acceptedFiles[0].path,
          content: binaryStr,
        });
      };
      reader.readAsBinaryString(file);
    });
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    onDrop,
  });

  if (loading) {
    return <LinearProgress />;
  }

  const uploadDocument = async (userInput) => {
    userInput.year = userInput.year.getFullYear();
    setLoading(true);
    await UploadGrades(
      {
        step: 1,
        content: files,
        ...userInput,
      },
      (resp) => {
        setLoading(false);
        setUploadPageHidden(true);
        setOpenTermAndYearModal(false);
        setPreviewPageHidden(false);
        setSuccessImport(resp.data.match);
        setFailImport(resp.data.unmatch);
        setMatchExcel(resp.data.matchExcel);
        setUnmatchExcel(resp.data.unmatchExcel);
        setConsistentImport(resp.data.consistent);
        setTermAndYear(resp.data.semester);
        toast.success(
          `Success with ${resp.data.match.length} matched and ${resp.data.unmatch.length} unmatched`
        );
      },
      (error) => {
        setLoading(false);
        setOpenTermAndYearModal(false);
        toast.error(errorMssgExtract(error));
      }
    );
  };

  const handleSave = async () => {
    rowSelectionModel.forEach((rowIndex) => {
      successImport[rowIndex].isSelected = true;
    });
    // Send request to backend
    setLoading(true);
    await UploadGrades(
      {
        step: 2,
        content: successImport,
        failImportData: failImport,
        term: termAndYear.term,
        year: termAndYear.year,
      },
      (resp) => {
        setLoading(false);
        debugger;
        setResultContent(resp.data);
        setPreviewPageHidden(true);
        setResultPageHidden(false);
        toast.success(
          `Successfully updated with ${resp.data.counts.failedCount} failed cases`
        );
      },
      (error) => {
        setLoading(false);
        toast.error(errorMssgExtract(error));
      }
    );
  };

  const generateExcel = () => {
    const wb = XLSX.read(unmatchExcel, { type: 'binary' });
    XLSX.writeFileXLSX(wb, 'unmatch_list.xlsx');
  };

  const generateMatchExcel = () => {
    const wb = XLSX.read(matchExcel, { type: 'binary' });
    XLSX.writeFileXLSX(wb, 'match_list.xlsx');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ConfirmDialog
        title="Warning"
        context="Press confirm button will save your selection and overwrite previous grade, you sure you want to do it?"
        cancelAction={() => setOpenDialog(false)}
        confirmAction={() => {
          handleSave();
          setOpenDialog(false);
        }}
        setOpen={setOpenDialog}
        openStatus={openDialog}
      />
      <TermAndYearSelectionModal
        open={openTermAndYearModal}
        handleClose={() => setOpenTermAndYearModal(false)}
        handleSubmitFunction={uploadDocument}
      />

      {/*Drag and drop page */}

      <div hidden={uploadPageHidden}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Auto upload grades by file
        </Typography>
        <MainCard
          sx={{ p: 0 }}
          title={`Selected File: ${files.name}`}
          hasActions
          buttonGroup={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => backToAdmin()}
              >
                <ArrowBackIcon sx={{ mr: 0.5 }} />
                Back to Admin
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenTermAndYearModal(true)}
              >
                <ArrowUpwardIcon sx={{ mr: 0.5 }} />
                Submit file
              </Button>
            </Stack>
          }
        >
          <StyledDropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isFocused={isFocused}
            isDragActive={isDragActive}
            selectedFileTitle={files.name}
          />
        </MainCard>
      </div>

      {/* Preview Page */}
      <div hidden={previewPageHidden} className="centeredLoad">
        <Stack spacing={2}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Preview before save to database
          </Typography>
          <HeaderButtons />

          {/*Preview Tables*/}
          <MainCard
            title="Successful: Matched Students"
            sx={{ my: 10 }}
            hasActions
            buttonGroup={
              <ButtonGroup disableElevation>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  Save Selected Grades
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateMatchExcel}
                >
                  Export Match List
                </Button>
              </ButtonGroup>
            }
          >
            <StudentGrid
              rows={successImport}
              columns={headConfig}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
          </MainCard>
          <MainCard
            title="Failed Import: Unmatched Students"
            sx={{ mt: 2 }}
            hasActions
            buttonGroup={
              <Button
                variant="contained"
                color="primary"
                onClick={generateExcel}
              >
                Export Failed List
              </Button>
            }
          >
            <StudentGrid
              rows={failImport}
              columns={failedHeadConfig}
              stickyHeader
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
          </MainCard>
        </Stack>
      </div>

      {/* Result Page */}
      <div hidden={resultPageHidden}>
        {!resultPageHidden && (
          <Suspense fallback={<div>isLoading...</div>}>
            <AdminImportGradesResultPage
              content={{ ...resultContent }}
              title="Result Recitation"
            />
          </Suspense>
        )}
      </div>
    </Box>
  );
}
