import React, { useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SelectionContextInitState = [];

const SelectionsContext = React.createContext(SelectionContextInitState);

function SelectionContextReducer(state, action) {
  const deleteEntry = (id, currentSelection) => {
    const cloneCurrentSelection = [...currentSelection];
    for (let i = 0; i < cloneCurrentSelection.length; i += 1) {
      const iterObj = cloneCurrentSelection[i];
      if (iterObj.suid === id) {
        cloneCurrentSelection.splice(i, 1);
      }
    }
    return cloneCurrentSelection;
  };

  const generateEntry = (
    course,
    newTime,
    newDay,
    newTerm,
    newYear,
    newGrade,
    newNote,
    newType,
    tID
  ) => {
    const result = {
      suid: uuidv4(),
      courseCredit: '',
      coursePrefix: '',
      courseUid: `${course.courseId}99`,
      courseTitle: course.courseTitle,
      courseId: course.courseId,
      courseDay: newDay,
      courseTime: newTime,
      courseTerm: newTerm,
      courseYear: newYear,
      courseGrade: newGrade,
      courseNote: newNote,
      courseType: newType,
      db_selectionId: course.db_selectionId,
      transactionId: tID,
    };
    for (let i = 0; i < course.courseData.length; i += 1) {
      const obj = course.courseData[i];
      if (
        obj.day === newDay &&
        obj.time === newTime &&
        obj.term === newTerm &&
        obj.start_year <= newYear &&
        obj.end_year >= newYear
      ) {
        result.courseUid = obj.uid;
        result.coursePrefix = obj.prefix;
        result.courseCredit = obj.credit;
      }
    }
    return result;
  };

  switch (action.type) {
    case 'ADD_SELECTION':
      return [...state, action.payload];
    case 'REMOVE_SELECTION':
      return deleteEntry(action.payload.id, state);
    case 'UPDATE_SELECTION':
      return [
        ...deleteEntry(action.payload.course.suid, state),
        generateEntry(
          action.payload.course,
          action.payload.time,
          action.payload.day,
          action.payload.term,
          action.payload.year,
          action.payload.grade,
          action.payload.note,
          action.payload.type,
          action.payload.transactionId
        ),
      ];
    case 'RESET_SELECTION':
      return SelectionContextInitState;
    case 'SET_SELECTION':
      return action.payload;
    default:
      throw new Error('Not allowed Method');
  }
}

function SelectionsContextProvider(props) {
  const [currSelState, currSelDispatch] = useReducer(
    SelectionContextReducer,
    SelectionContextInitState
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SelectionsContext.Provider value={{ currSelState, currSelDispatch }}>
      {props.children}
    </SelectionsContext.Provider>
  );
}

export { SelectionsContext, SelectionsContextProvider };
