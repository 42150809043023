import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import useChartStat from '../../api/hooks/useChartStat';
import MainCard from '../../components/MainCard';
// Charts
import StudentCountTideSlide from './charts/StudentCountTideSlide';
import TypeChart from './charts/TypeChart';
import StartCountColumn from './charts/StartCountColumn';
import GradCountColumn from './charts/GradCountColumn';
import GenderChart from './charts/GenderChart';
import AdvisorChart from './charts/AdvisorChart';
import StudentTable from './charts/StudentTable';
import CourseEnrollCountChart from './charts/CourseEnrollCountChart';
import StuEnrollCountChart from './charts/StuEnrollCountChart';
import { ENROLLSTATUS, CURRENT } from '../../ENV';
import StuEthnicityChart from './charts/StuEthnicityChart';

function Dashboard() {
  const { studentList: stuList, isLoading: isLoadingA } = useStuListByAdv();

  const { data: stuCountData, isLoading: isLoadingB } = useChartStat(
    '/students/getStudentCountTide'
  );
  const { data: appearedSemester, isLoading: isLoadingE } = useChartStat(
    '/students-courses/getAppearedSemester'
  );

  if (isLoadingA || isLoadingB || isLoadingE) {
    return <LinearProgress />;
  }

  return (
    <Grid container sx={{ pt: 0 }} rowSpacing={4.5} columnSpacing={2.75}>
      {/* Title */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      {/* Row 1 */}
      <StudentCountTideSlide
        data={stuCountData}
        appearedSemester={appearedSemester}
        title={'Incoming Students'}
        filterType={'Start'}
        defaultStartTerm={CURRENT.YEAR - 2 + ' ' + CURRENT.TERM}
        defaultEndTerm={CURRENT.YEAR + +1 + ' ' + CURRENT.TERM}
      />

      {/* row 2 */}
      <StudentCountTideSlide
        data={stuCountData}
        appearedSemester={appearedSemester}
        title={'Graduating Students'}
        filterType={'Graduate'}
        defaultStartTerm={CURRENT.YEAR - 1 + ' ' + CURRENT.TERM}
        defaultEndTerm={CURRENT.YEAR + 2 + ' ' + CURRENT.TERM}
      />

      {/* row 3 */}
      <TypeChart appearedSemester={appearedSemester} />
      <GenderChart appearedSemester={appearedSemester} />
      <StuEthnicityChart appearedSemester={appearedSemester} />

      {/* row 4 */}
      <AdvisorChart />
      <StuEnrollCountChart appearedSemester={appearedSemester} />

      {/* row 5 */}
      {/*<Grid item xs={12} md={12} lg={12}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">Student Table</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item />*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 2 }} content={false}>*/}
      {/*    <StudentTable studentList={studentList} />*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}

      {/* row6 */}
      <CourseEnrollCountChart appearedSemester={appearedSemester} />
    </Grid>
  );
}

export default Dashboard;
