import axios from 'axios';
import { CONFIG } from '../../../ENV';
import { errorMssgExtract } from '../../../utils/errorHandleTools';

export default async function UpdateToCertification(Pbody) {
  return axios
    .post(
      `${CONFIG.PORTAL}/students/updateToCertification`,
      { students: Pbody },
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('authenticationStatus')).jwtToken
          }`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return errorMssgExtract(error);
    });
}
