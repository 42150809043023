import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import '../../index.css';
import { toast } from 'react-hot-toast';
import { CONFIG } from '../../ENV';

function ResetPassword() {
  const history = useHistory();
  const [resetPassword, setResetPassword] = useState('');
  const [resetPasswordConfirmation, setResetPasswordComfirmation] =
    useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromURL = params.get('code');
    if (tokenFromURL) {
      setToken(tokenFromURL);
    }
  }, []);

  const resetPasswordSend = async () => {
    try {
      const res = await fetch(`${CONFIG.PORTAL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: token,
          password: resetPassword,
          passwordConfirmation: resetPasswordConfirmation,
        }),
      }).catch((error) => console.log(error));
      const result = await res.json();
      if (result && result.statusCode != 400) {
        toast.success(
          'Password reset successful! You can now log in with your new password.'
        );
        history.push('/');
      } else {
        toast.error(
          `Password reset failed: ${
            result.message[0].messages[0].message || 'Unknown error'
          }`
        );
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.toString()}`);
    }
  };

  return (
    <div>
      {/*<SiteHeader />*/}
      <Form className="login-form mt-5">
        <h2 className="text-center p-3">Reset Your Password</h2>
        <FormGroup>
          <Label>Password</Label>
          <Input
            type="password"
            placeholder="Enter new password here"
            onChange={(e) => setResetPassword(e.target.value)}
            value={resetPassword}
          />
        </FormGroup>
        <FormGroup>
          <Label>Password Comfirmation</Label>
          <Input
            type="password"
            placeholder="Enter password again here"
            onChange={(e) => setResetPasswordComfirmation(e.target.value)}
            value={resetPasswordConfirmation}
          />
        </FormGroup>
        <Button color="primary" block onClick={() => resetPasswordSend()}>
          Reset Password
        </Button>
        <div className="text-center mt-3">
          <a className="subtitle-link" href="/">
            Back to Login
          </a>
        </div>
      </Form>
    </div>
  );
}

export default withRouter(ResetPassword);
