import useSWR from 'swr';
import { API } from '../api';

export default function useUploadFiles(url) {
  const fetcher = (link) => API.get(link);
  const { data, error, isLoading } = useSWR(url, fetcher);
  const GURecognizer = (fileName) => fileName.toLowerCase().includes('gu');

  const res = [];

  if (data) {
    data.data.forEach((item) => {
      res.push({
        ...item,
        fileType: GURecognizer(item.name)
          ? 'Auto grade import result'
          : 'Other',
      });
    });
  }

  return {
    data: res,
    error,
    isLoading,
  };
}
