import React from 'react';
import {
  Button,
  List,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  ListSubheader,
  ListItemSecondaryAction,
} from '@mui/material';
import { Delete, Clear, WifiProtectedSetup } from '@mui/icons-material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import RichTextEditor from './RichTextEditor';
import { CONFIG } from '../../../../ENV';

function Attachments({ attachList, setAttachList, removingAttachment }) {
  return (
    <Paper style={{ marginRight: '25px', width: '60%' }}>
      <List
        sx={{ pt: 0, pb: 0, m: 0 }}
        subheader={
          <ListSubheader component="div" id="adding_attachments">
            Attachments
          </ListSubheader>
        }
      >
        {attachList.map((attachFile) =>
          attachFile.id ? (
            <ListItem key={attachFile.name}>
              <a
                href={CONFIG.PORTAL + attachFile.url}
                target="_blank"
                aria-label="view"
                rel="noopener noreferrer"
              >
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      textDecoration:
                        attachFile.isDisplay === undefined ||
                        attachFile.isDisplay === true
                          ? 'none'
                          : 'line-through',
                    },
                  }}
                  primary={attachFile.name}
                />
              </a>
              <ListItemSecondaryAction style={{ right: '5%', left: 'auto' }}>
                <IconButton
                  onClick={(event) =>
                    removingAttachment(attachList, setAttachList, attachFile)
                  }
                  edge="end"
                  aria-label="remove/recover"
                >
                  {attachFile.isDisplay === undefined ||
                  attachFile.isDisplay === true ? (
                    <Clear />
                  ) : (
                    <WifiProtectedSetup />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (
            <ListItem key={attachFile.name}>
              <ListItemText
                primaryTypographyProps={{ style: { textOverflow: 'ellipsis' } }}
                primary={attachFile.name}
              />
              <ListItemSecondaryAction style={{ right: '5%', left: 'auto' }}>
                <IconButton
                  onClick={(event) =>
                    removingAttachment(attachList, setAttachList, attachFile)
                  }
                  edge="end"
                  aria-label="delete"
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        )}
      </List>
    </Paper>
  );
}

export default function RichTextWithFiles({
  notesInput,
  setNotesInput,
  attachList,
  setAttachList,
}) {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const addingAttachment = (targetList, setTargetList, file) => {
    let hasSameName = false;
    for (let i = 0; i < targetList.length; i++) {
      if (file.name == targetList[i].name) {
        hasSameName = true;
        toast.error('File with same name already exist');
        break;
      }
    }
    if (!hasSameName) {
      setTargetList([...targetList, file]);
    }
  };

  const removingAttachment = (targetList, setTargetList, file) => {
    for (let i = 0; i < targetList.length; i++) {
      if (file.name == targetList[i].name) {
        let newList = [...targetList];
        if (file.id) {
          if (targetList[i].isDisplay === undefined) {
            newList[i].isDisplay = false;
          } else {
            newList[i].isDisplay = !targetList[i].isDisplay;
          }
        } else {
          newList = targetList.filter((item) => item.name !== file.name);
        }
        setTargetList(newList);
        break;
      }
    }
  };
  return (
    <>
      <RichTextEditor notesInput={notesInput} setNotesInput={setNotesInput} />
      <Button
        component="label"
        role={undefined}
        variant="outlined"
        style={{ float: 'right', marginTop: '8px' }}
        startIcon={<AttachFileIcon />}
      >
        Attach File
        <VisuallyHiddenInput
          type="file"
          onChange={(e) =>
            addingAttachment(attachList, setAttachList, e.target.files[0])
          }
        />
      </Button>
      <Grid justifyContent="flex-start" style={{ paddingTop: '15px' }}>
        {attachList.length > 0 ? (
          <Attachments
            attachList={attachList}
            setAttachList={setAttachList}
            removingAttachment={removingAttachment}
          />
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
