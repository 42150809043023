import React from 'react';
import useSWR from 'swr';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Stack,
  Chip,
} from '@mui/material';
import SelectionServices from '../../../api/services/selectionServices';
import TemplateValidationTable from './TemplateValidationTable';

// {
//   "template": "RHIA-ONCMP-FT-Fall",
//   "result": [
//       {
//           "term": "Fall",
//           "isConflict": false,
//           "isMissing": false,
//           "missingReport": []
//       },
//       {
//           "term": "Spring",
//           "isConflict": false,
//           "isMissing": false,
//           "missingReport": []
//       },
//       {
//           "term": "Summer",
//           "isConflict": true,
//           "isMissing": false,
//           "missingReport": []
//       },
//       {
//           "term": "Fall",
//           "isConflict": false,
//           "isMissing": true,
//           "missingReport": [
//               {
//                   "courseId": 2230,
//                   "courseTerm": "Fall",
//                   "courseDay": "THR"
//               }
//           ]
//       }
//   ]
// }

function TemplateValidationReport(props) {
  const { data, error, isLoading } = useSWR(
    'validation template',
    SelectionServices.validateTemplate
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Template Validation
      </Typography>
      <Stack spacing={2}>
        {data.map((res) => {
          const noticeableContent = res.result.filter(
            (row) =>
              row.isConflict || row.isMissing || row.missingReport.length > 0
          );
          if (noticeableContent.length === 0) return <></>;
          return (
            <TemplateValidationTable
              data={noticeableContent}
              title={res.template}
            />
          );
        })}
      </Stack>
    </div>
  );
}

export default TemplateValidationReport;
