import React from 'react';
import useSWR from 'swr';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import selectionServices from '../services/selectionServices';

export default function useSelectionByStuId(id) {
  const { data, error, isLoading } = useSWR(
    id ? [id, 'SLCT'] : null,
    ([studentId]) => selectionServices.getSelectedCoursesForStu(studentId)
  );

  return {
    data,
    isLoading,
    isError: error ? errorMssgExtract(error) : error,
  };
}
