import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
  authenticationRole: false,
  jwtToken: false,
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('authenticationStatus');
    if (serializedState === null) {
      return defaultState;
    }

    const authState = JSON.parse(serializedState);
    const { jwtToken } = authState;
    const expireTime = parseJwt(jwtToken).exp;
    const nowTimeSeconds = new Date().getTime() / 1000;
    // when token expired, login again
    if (nowTimeSeconds > expireTime) {
      return {
        isExpired: true,
        authenticationRole: false,
        jwtToken: false,
      };
    }
    return authState;
  } catch (err) {
    return defaultState;
  }
};

const initialState = loadState();

export const authenticationStatus = createSlice({
  name: 'authenticationStatus',
  initialState,
  reducers: {
    updateStatus: (status, action) => {
      if (action.payload) {
        status.authenticationRole = action.payload.authenticationRole;
        status.jwtToken = action.payload.jwtToken;
      } else {
        status.authenticationRole = false;
        status.jwtToken = false;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateStatus: updateAuthStatus } = authenticationStatus.actions;

export default authenticationStatus.reducer;
