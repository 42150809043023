import { API } from '../api';

const CertificationServices = {
  getCrtfNoteByStudentId(studentId) {
    return API.get(
      `student-certifications?student_id=${studentId}&deactivate=false`
    ).then((res) => res.data);
  },
};

export default CertificationServices;
