import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import SemesterDropDown from './SemesterDropDown';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { ENROLLSTATUS } from '../../ENV';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'track',
    headerName: 'Track',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatStartTerm',
    headerName: 'Start',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatGradTerm',
    headerName: 'Expt Grad',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'plan_available',
    headerName: 'POS',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'medical_terminology',
    headerName: 'Med Status',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    type: 'string',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
];

export default function CohortView() {
  const { studentList, appearedStartSemester, isLoading, isError } =
    useStuListByAdv();
  const [startSemester, setStartSemester] = useState('');

  // debugger;

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const handleChangeTerm = (e) => {
    const term = e.target.value;
    setStartSemester(term);
  };

  const mutateData = () => {
    const filtratedData = studentList.filter(
      (item) =>
        item.enrollment_status === ENROLLSTATUS.GRADUATE ||
        item.enrollment_status === ENROLLSTATUS.ACTIVE ||
        item.enrollment_status === ENROLLSTATUS.PROBATION
    );

    if (startSemester) {
      return filtratedData.filter(
        (item) => item.concatStartTerm === startSemester
      );
    }
    return filtratedData;
  };

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        {!startSemester && (
          <Grid item>
            <Typography variant="h5">Cohort View</Typography>
          </Grid>
        )}
        {startSemester && (
          <Grid item>
            <Typography variant="h5">
              {startSemester}: {mutateData().length} student(s) start their
              program{' '}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <SemesterDropDown
            title="select start semester : "
            value={startSemester}
            onChange={handleChangeTerm}
            appearedSemester={appearedStartSemester}
          />
        </Grid>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} headConfig={columns} />
        </MainCard>
      </Grid>
    </Grid>
  );
}
