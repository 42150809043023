import { API } from '../api';

const mediaService = {
  // upload file
  async uploadResume(Pbody, targetId) {
    const formData = new FormData();
    formData.append('files', Pbody);
    const imageId = (await API.post('/upload', formData)).data[0].id;
    await API.put(`/Students/${targetId}`, { resume: imageId });
  },

  async uploadAdDoc(Pbody, targetId) {
    const formData = new FormData();
    formData.append('files', Pbody);
    const imageId = (await API.post('/upload', formData)).data[0].id;
    await API.put(`/Students/${targetId}`, { admission_package: imageId });
  },

  async uploadNotesAttach(newAttachList, keepingList, NoteId) {
    let newList = [];
    if (newAttachList.length > 0) {
      const formData = new FormData();
      newAttachList.forEach((attachment) => {
        formData.append('files', attachment);
      });
      const newAttachments = await API.post('/upload', formData);
      newList = newAttachments.data;
    }
    const fullAttachList = keepingList.concat(newList);
    if (fullAttachList.length > 0) {
      const addingIdsToAwait = [];
      fullAttachList.forEach((attachment) => {
        addingIdsToAwait.push(
          API.put(`/Notes/${NoteId}`, {
            attachments: attachment,
          })
        );
      });
      await Promise.all(addingIdsToAwait);
    }
  },

  // delete file
  async deleteFile(fileId) {
    await API.delete(`/upload/files/${fileId}`).then((res) => res.data);
  },
  // delete files
  async deleteFiles(fileIds) {
    const addingIdsToAwait = [];
    for (let i = 0; i < fileIds.length; i++) {
      addingIdsToAwait.push(
        API.delete(`/upload/files/${fileIds[i]}`).then((res) => res.data)
      );
    }
    await Promise.all(addingIdsToAwait);
  },
};

export default mediaService;
