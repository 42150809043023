import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { isEquivalent } from '../../utils/generalTools';
import FalseCheck from '../CoursePlanner/components/FalseCheck';

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'track',
    headerName: 'Track',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatStartTerm',
    headerName: 'Start',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatGradTerm',
    headerName: 'Expt Grad',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'hipaa_training',
    headerName: 'HIPPA Training',
    type: 'boolean',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'security_clearance',
    headerName: 'Security Clearance',
    type: 'boolean',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'medical_terminology',
    headerName: 'Medical Terminology',
    type: 'boolean',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'title_ix_status',
    headerName: 'TitlelIX Status',
    type: 'boolean',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
];

// ==============================|| Requirement Report - Table ||============================== //

function ReqReport() {
  const { studentList, appearedSemester, isLoading, isError } =
    useStuListByAdv();
  const [hipaa, setHipaa] = useState(false);
  const [security, setSecurity] = useState(false);
  const [medical, setMedical] = useState(false);
  const [titleIX, setTitleIX] = useState(false);

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const mutateData = () =>
    studentList.filter(
      (item) =>
        isEquivalent(hipaa, item.hipaa_training) &&
        isEquivalent(security, item.security_clearance) &&
        isEquivalent(medical, item.medical_terminology) &&
        isEquivalent(titleIX, item.title_ix_status)
    );

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Requirement Report List</Typography>
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="overline"
              textAlign="center"
              color="textSecondary"
            >
              SELECT STUDENT DO NOT HAVE
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={<FalseCheck />}
                checked={hipaa}
                onChange={(event) => setHipaa(event.target.checked)}
                label="HIPAA"
              />
              <FormControlLabel
                control={<FalseCheck />}
                checked={security}
                onChange={(event) => setSecurity(event.target.checked)}
                label="SECURITY"
              />
              <FormControlLabel
                control={<FalseCheck />}
                checked={medical}
                onChange={(event) => setMedical(event.target.checked)}
                label="MEDICAL"
              />
              <FormControlLabel
                control={<FalseCheck />}
                checked={titleIX}
                onChange={(event) => setTitleIX(event.target.checked)}
                label="TitleIX"
              />
            </FormGroup>
          </Grid>
        </Stack>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} headConfig={headCells} />
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default ReqReport;
