import React, { useContext, useEffect, useState } from 'react';
import { TableCell, TableRow, Checkbox, Tooltip } from '@mui/material';
import { PriorityHighOutlined } from '@mui/icons-material';
import _ from 'underscore';
import AutoCompleteTag from './AutoCompleteTag';
import { CertificationContext } from '../../../../context/CertificationContext';

function CertificationRow({ id, title, optionData, certificationNote }) {
  const { crtfState, crtfDispatch } = useContext(CertificationContext);
  const [tagValue, setTagValue] = useState([]);
  const [checkFlag, setCheckFlag] = useState(false);
  const [disableFlag, setDisableFlag] = useState(true);
  const [mark, setMark] = useState(null);
  // Reflect content from DB
  useEffect(() => {
    _.map(crtfState, (value, key) => {
      if (Number(key) === id) {
        setTagValue(value.content);
        setCheckFlag(value.check);
        setDisableFlag(!value.check);
      }
    });
  }, []);

  // Activate CRTF state update
  useEffect(() => {
    if (tagValue.length > 0 || checkFlag) {
      crtfDispatch({
        type: 'UPDATE_CRTF',
        payload: { check: checkFlag, id, title, content: tagValue },
      });
    }

    if (checkFlag && tagValue.length == 0) {
      setMark(
        <Tooltip title="Instruction is required to fullfill certification">
          <PriorityHighOutlined />
        </Tooltip>
      );
    } else {
      setMark(null);
    }
  }, [tagValue, checkFlag]);

  const handleChecked = (event) => {
    setCheckFlag(event.target.checked);
    setDisableFlag(false);
    if (!event.target.checked) {
      setTagValue([]);
      crtfDispatch({
        type: 'REMOVE_CRTF',
        payload: { id },
      });
    }
  };

  return (
    <TableRow>
      <TableCell
        style={{
          width: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          borderStyle: 'border-box',
        }}
      >
        <Checkbox checked={checkFlag} onChange={handleChecked} /> {title}
      </TableCell>
      <TableCell>{mark}</TableCell>
      <TableCell align="right">
        <AutoCompleteTag
          id={id}
          disableFlag={disableFlag}
          title={title}
          optionData={optionData}
          value={tagValue}
          setValue={setTagValue}
        />
        <p style={{ margin: '0px', color: 'gray' }}>{certificationNote}</p>
      </TableCell>
    </TableRow>
  );
}

export default CertificationRow;
