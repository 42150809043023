/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Label } from 'reactstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import { CONFIG } from '../../../ENV';
import { errorMssgExtract } from '../../../utils/errorHandleTools';
import InfoData from '../../StudentInfoView/InfoData';
import { checkCertiTrack } from '../../../utils/courseTools';

function TemplateSelection({
  show,
  student,
  onHide,
  setCurrentSelection,
  transactionId,
  programSemesters,
  fetchConfig,
}) {
  const stuInfo = student.studentInfo;
  const [studentProgram, setStudentProgram] = useState(
    stuInfo.track ? stuInfo.track : 'HI'
  );
  const [studentStatus, setStudentStatus] = useState(stuInfo.status);
  const [studentTrackType, setStudentTrackType] = useState('');

  useEffect(() => {
    if (!checkCertiTrack(studentProgram)) {
      setStudentTrackType('Graduate');
      setStudentStatus(stuInfo.status ? stuInfo.status : 'FT');
    } else {
      setStudentTrackType('Certification');
      setStudentStatus('');
    }
  }, [studentProgram]);

  const autoPopulate = async () => {
    await axios
      .post(
        `${CONFIG.PORTAL}/templates/getTemplateByStudent`,
        {
          startYear: student.studentInfo.start_year,
          startTerm: student.studentInfo.start_term,
          type: student.studentInfo.type,
          status: studentStatus,
          program: studentProgram,
          programSemesters: programSemesters,
        },
        fetchConfig
      )
      .then((res) => {
        // Apply Selection
        const { data } = res.data;
        if (data && data.length !== 0) {
          const padArr = data.map((item) => ({
            ...item,
            transactionId,
          }));
          setTimeout(() => {
            setCurrentSelection({ type: 'SET_SELECTION', payload: padArr });
          }, 0);
        }
        // Notification
        switch (res.data.status) {
          case 'success':
            toast.success(res.data.mssg);
            break;
          case 'missing':
            toast(res.data.mssg, { icon: '❗' });
            break;
          case 'warning':
            toast.error(res.data.mssg);
            break;
          default:
            toast.success('Action done');
            break;
        }
        onHide();
      })
      .catch((error) => {
        toast.error(errorMssgExtract(error));
      });
  };

  const handleStatusSelect = (event) => {
    setStudentStatus(event.target.value);
  };

  const handleProgramSelect = (event) => {
    setStudentProgram(event.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Template Selection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <p className="mt-2">
            NOTICE : Auto-populate will overwrite all your selections
          </p>
          <Label>Track for student</Label>
          <Form.Select
            defaultValue={studentProgram}
            value={studentProgram}
            aria-label="Default select example"
            onChange={handleProgramSelect}
          >
            {InfoData.trackOptions.map((item, index) => {
              if (item.type === studentTrackType) {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              }
            })}
          </Form.Select>
          {studentTrackType === 'Graduate' ? (
            <>
              <Label className="mt-2">Student Status</Label>
              <Form.Select
                defaultValue={studentStatus}
                value={studentStatus}
                aria-label="Default select example"
                onChange={handleStatusSelect}
              >
                <option value="FT">Full-time</option>
                <option value="PT">Part-time</option>
              </Form.Select>
            </>
          ) : (
            <></>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={autoPopulate}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TemplateSelection;
