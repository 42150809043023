import React from 'react';
import { ButtonBase, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import icon from './NavBar/favicon.ico';

function Logo({ sx, to }) {
  return (
    <ButtonBase disableRipple component={Link} to={to} sx={sx}>
      <img
        alt="HI-HUB"
        src={icon}
        width="35"
        height="35"
        className="d-inline-block align-top"
      />{' '}
      <Typography sx={{ ml: 1 }}>Univ of Pitt - SHRS</Typography>
    </ButtonBase>
  );
}

export default Logo;
