import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { getYear } from 'date-fns';
import useNonInitUpdateEffect from '../../../../hooks/useNonInitUpdateEffect';
import { isEmpty } from '../../utils';
import InfoData from '../../../StudentInfoView/InfoData';

export default function MedTermRow({
  title,
  setMedTerm,
  medTerm,
  medYear,
  medGrade,
}) {
  const [year, setYear] = useState(medYear);
  const [term, setTerm] = useState(medTerm || '');
  const [grade, setGrade] = useState(medGrade || '');

  useNonInitUpdateEffect(() => {
    const medTermObj = {
      didUpdate: true,
      medical_terminology: true,
      med_terminology_year: isEmpty(year) ? null : year,
      med_terminology_term: isEmpty(term) ? null : term,
      med_terminology_fulfill: isEmpty(grade) ? null : grade,
    };
    if (isEmpty(year) && isEmpty(term) && isEmpty(grade)) {
      medTermObj.medical_terminology = false;
    }
    setMedTerm(medTermObj);
  }, [year, term, grade]);

  const handleTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleYear = (date) => {
    setYear(isEmpty(date) ? null : getYear(date).toString());
  };

  const handleTypeGrade = (event) => {
    setGrade(event.target.value);
  };

  return (
    <TableRow>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> {title} </TableCell>
      <TableCell> </TableCell>
      <TableCell>
        <FormControl sx={{ minWidth: 100, minHeight: 40 }}>
          <Select
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
            value={term}
            onChange={handleTerm}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Spring">Spring</MenuItem>
            <MenuItem value="Summer">Summer</MenuItem>
            <MenuItem value="Fall">Fall</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <DatePicker
          className="form-control"
          selected={year ? new Date(year, 1) : null}
          onChange={handleYear}
          yearItemNumber={10}
          dateFormat="yyyy"
          showYearPicker
          isClearable
          closeOnScroll
        />
      </TableCell>
      <TableCell>
        <FormControl sx={{ minWidth: 170, minHeight: 40 }}>
          <Select
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
            value={grade}
            onChange={handleTypeGrade}
          >
            {InfoData.medTerminologyFulFilledOption.map((item) => (
              <MenuItem
                hidden={item.hidden}
                key={item.value}
                value={item.value}
              >
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
