/* eslint-disable no-unused-expressions */
import _ from 'underscore';
import { containsCourse } from '../../utils/courseTools';
import { splitTerm } from '../../utils/termTools';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 25,
  bottom: 20,
  left: 'auto',
  zIndex: 1500,
  position: 'absolute',
};

/**
 *
 * @param {*} POS POS Json Object
 * @param {Array} courseArr course information
 * @param {Array} currentSelectionArr Student selection data
 * @returns {[Array, Array, Array]} [requiredCourses, electiveCourses, otherCourses]
 */

function aggregateCourseRowData(POS, courseArr, currentSelectionArr) {
  // Generate Course Order
  const requiredCourseOrder = [];
  const electiveCourseOrder = [];
  POS.Required &&
    Object.entries(POS.Required).forEach(([key]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        requiredCourseOrder.push(courseId);
      }
    });
  POS.Elective &&
    Object.entries(POS.Elective).forEach(([key]) => {
      const courseId = Number(key.slice(3, 7));
      if (courseId) {
        electiveCourseOrder.push(courseId);
      }
    });

  const requiredCourses = [];
  const electiveCourses = [];
  const cornerCaseSelections = [];

  // Handle Corner case HI9999 and HI2999;
  let HI2999 = {};
  let HI9999 = {};
  for (let i = 0; i < currentSelectionArr.length; i++) {
    const obj = currentSelectionArr[i];
    if (obj.courseId == 9999 || obj.courseId == 2999) {
      cornerCaseSelections.push(obj);
    }
  }
  courseArr = _.filter(courseArr, (item) => {
    if (item.courseId === '9999' || item.courseId === '2999') {
      item.courseId == 9999 ? (HI9999 = item) : (HI2999 = item);
      return false;
    }
    return true;
  });

  for (let i = 0; i < cornerCaseSelections.length; i++) {
    const obj2 = cornerCaseSelections[i];
    courseArr.push({
      ...(obj2.courseId == 2999 ? HI2999 : HI9999),
      suid: obj2.suid,
      db_selectionId: obj2.db_selectionId,
      courseTerm: obj2.courseTerm,
      courseYear: obj2.courseYear,
      courseDay: obj2.courseDay,
      courseTime: obj2.courseTime,
      courseGrade: obj2.courseGrade,
      courseNote: obj2.courseNote,
      courseType: obj2.courseType,
      courseUid: obj2.courseUid,
      isExpired: obj2.isExpired,
    });
  }

  if (!containsCourse(2999, courseArr)) {
    courseArr.push(HI2999);
  }

  // Assemble Displayed Course Data
  for (let i = 0; i < courseArr.length; i += 1) {
    const obj = courseArr[i];
    if (obj.courseId == 9999 || obj.courseId == 2999) {
      continue;
    }
    courseArr[i].courseTerm = '';
    courseArr[i].courseYear = '';
    courseArr[i].courseDay = '';
    courseArr[i].courseTime = '';
    courseArr[i].courseGrade = '';
    courseArr[i].courseNote = '';
    courseArr[i].courseType = '';
    courseArr[i].courseUid = '';
    courseArr[i].db_selectionId = '';
    courseArr[i].isExpired = false;
    // Match
    for (let j = 0; j < currentSelectionArr.length; j += 1) {
      const obj2 = currentSelectionArr[j];
      if (obj.courseId === obj2.courseId) {
        courseArr[i].suid = obj2.suid;
        courseArr[i].db_selectionId = obj2.db_selectionId;
        courseArr[i].courseTerm = obj2.courseTerm;
        courseArr[i].courseYear = obj2.courseYear;
        courseArr[i].courseDay = obj2.courseDay;
        courseArr[i].courseTime = obj2.courseTime;
        courseArr[i].courseGrade = obj2.courseGrade;
        courseArr[i].courseNote = obj2.courseNote;
        courseArr[i].courseType = obj2.courseType;
        courseArr[i].courseUid = obj2.courseUid;
        courseArr[i].isExpired = obj2.isExpired;
      }
    }
  }

  // Match order
  for (let i = 0; i < requiredCourseOrder.length; i += 1) {
    let indexFlag = -1;
    courseArr.forEach((item, index) => {
      if (Number(item.courseId) === requiredCourseOrder[i]) {
        requiredCourses.push(item);
        indexFlag = index;
      }
    });
    courseArr.splice(indexFlag, 1);
  }
  for (let i = 0; i < electiveCourseOrder.length; i += 1) {
    let indexFlag = -1;
    courseArr.forEach((item, index) => {
      if (Number(item.courseId) === electiveCourseOrder[i]) {
        electiveCourses.push(item);
        indexFlag = index;
      }
    });
    courseArr.splice(indexFlag, 1);
  }

  // Fixed order
  const otherCourses = _.sortBy([...courseArr], (item) => {
    if (item.courseId == 9999 || item.courseId == 2999) {
      return Number(item.courseId + item.db_selectionId);
    }
    return Number(item.courseId);
  });

  // Whatever left is other courses
  return [requiredCourses, electiveCourses, otherCourses];
}

/**
 *
 * @param {Date} time
 * @param {String} day
 * @param {Enumerator} term
 * @param {String} year
 * @returns
 */
// Whether selected
function isSelected(time, day, term, year) {
  return !(!time && !day && !term && !year);
}

// Selected but not complete which means miss anything
function isComplete(time, day, term, year) {
  return time && day && term && year;
}

// Display not accurate to selection from DB
function isAccurate(course, time, day, term, year) {
  try {
    let flag = false;
    course.courseOptions[term].forEach((item) => {
      if (item[0] === day && item[1] === time) {
        flag = true;
      }
    });
    return flag;
  } catch (e) {
    return false;
  }
}

function isFulfilled(currentSelection) {
  let isRunnable = true;
  try {
    currentSelection.forEach((item) => {
      const time = item.courseTime;
      const day = item.courseDay;
      const term = item.courseTerm;
      const year = item.courseYear;
      if (
        isSelected(time, day, term, year) &&
        !isComplete(time, day, term, year)
      ) {
        isRunnable = false;
      }
    });
  } catch (error) {
    return false;
  }
  return isRunnable;
}

function isEmpty(target) {
  return !(target && target !== '');
}

/**
 * Description placeholder
 * @date 10/11/2023 - 6:40:54 PM
 *
 * @param {string} courseType
 * @param {string} studentType
 */
function isConsistent(courseType, studentType) {
  if (courseType != studentType) {
    return false;
  }
  return true;
}

function getTermsUntilLatest(startTerm, programDuration, endTerm) {
  const res = [];
  const terms = { 0: 'Spring', 1: 'Summer', 2: 'Fall' };

  const [startYear, startSeason] = splitTerm(startTerm, false);
  const [endYear, endSeason] = splitTerm(endTerm, false);
  //
  // const startYear = parseInt(startTerm.slice(0, 4), 10);
  // const startSeason = startTerm.slice(4);
  // const endYear = parseInt(endTerm.slice(0, 4), 10);
  // const endSeason = endTerm.slice(4);

  const startIndex = Object.values(terms).indexOf(startSeason);
  const endIndex = Object.values(terms).indexOf(endSeason);

  const totalTermsFromStart = programDuration * Object.keys(terms).length;
  const totalTermsByendTerm =
    (endYear - startYear) * Object.keys(terms).length +
    (endIndex - startIndex + 1);

  const durations = Math.max(totalTermsByendTerm, totalTermsFromStart);

  for (let i = 0; i < durations; i++) {
    const year =
      Number(startYear) +
      Math.floor((startIndex + i) / Object.keys(terms).length);
    const term = terms[(startIndex + i) % Object.keys(terms).length];
    res.push(`${year}${term}`);
  }
  return res;
}

export {
  fabStyle,
  aggregateCourseRowData,
  isSelected,
  isComplete,
  isAccurate,
  isFulfilled,
  isEmpty,
  isConsistent,
  getTermsUntilLatest,
};
