import React from 'react';
import { Input, Label } from 'reactstrap';

function ViewOrEditSelector({
  updateInfo,
  fieldName,
  fieldValue,
  isDisabled,
  studentInfoKey,
  selectOptions,
}) {
  function handleChange(changedValue, fieldName) {
    updateInfo(changedValue, fieldName);
  }
  return (
    <>
      <td>
        <Label for={studentInfoKey}>{fieldName}</Label>

        <Input
          disabled={isDisabled}
          type="select"
          value={fieldValue || ''}
          onChange={(e) => handleChange(e.target.value, studentInfoKey)}
        >
          <option value="" disabled>
            {fieldName}
          </option>
          {selectOptions.map((x) => (
            <option
              key={x.label}
              title={x.label}
              value={x.value}
              hidden={x.hidden}
            >
              {x.label}
            </option>
          ))}
        </Input>
      </td>
    </>
  );
}

export default ViewOrEditSelector;
