import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

function ScrollTop({ children }) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 10,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return children || null;
}

ScrollTop.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default ScrollTop;
