import React, { useState } from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import Logo from '../assets/logo.png';

function Profile({ userName, logoutFunction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, justifyContent: 'flex-end' }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: 'grey.100',
          borderRadius: 1,
          '&:hover': { bgcolor: 'grey.200' },
        }}
        aria-label="open profile"
        aria-haspopup="true"
        aria-controls={open ? 'profile-grow' : undefined}
        onClick={handleClick}
        // ref={anchorRef}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            alt="profile user"
            src={Logo}
            sx={{ width: 25, height: 25 }}
          />
          <Typography variant="subtitle1">{userName}</Typography>
        </Stack>
      </ButtonBase>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={logoutFunction}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}

export default Profile;
