import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { CURRENT, GRADEPOINTS, ENROLLSTATUS } from '../../ENV';

export default function PotentialDiscontinuedReport() {
  const { studentList, isLoading, isError } = useStuListByAdv();
  const [gradSemester, setGradSemester] = useState('');

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const handleChangeTerm = (e) => {
    const term = e.target.value;
    setGradSemester(term);
  };

  let compare_year1 = 1900;
  let compare_year2 = 1900;
  let compare_semester1 = '';
  let compare_semester2 = '';
  // Determine the last semester, and the last last semester
  if (CURRENT.TERM == 'Spring') {
    compare_year1 = CURRENT.YEAR - 1;
    compare_semester1 = 'Fall';
    compare_year2 = CURRENT.YEAR - 1;
    compare_semester2 = 'Summer';
  } else if (CURRENT.TERM == 'Summer') {
    compare_year1 = CURRENT.YEAR;
    compare_semester1 = 'Spring';
    compare_year2 = CURRENT.YEAR - 1;
    compare_semester2 = 'Fall';
  } else {
    compare_year1 = CURRENT.YEAR;
    compare_semester1 = 'Summer';
    compare_year2 = CURRENT.YEAR;
    compare_semester2 = 'Spring';
  }
  const mutateData = () => {
    let filtratedData = studentList.filter((item) => {
      const validStatus =
        item.enrollment_status === ENROLLSTATUS.ACTIVE ||
        item.enrollment_status === ENROLLSTATUS.PROBATION ||
        item.enrollment_status === ENROLLSTATUS.ABSENT;

      const hasInvalidGrade1 = item.courses.some(
        (course) =>
          (course.grade === null || course.grade === '') &&
          compare_year1 === course.year &&
          compare_semester1 === course.term
      );

      const hasInvalidGrade2 = item.courses.some(
        (course) =>
          (course.grade === null || course.grade === '') &&
          compare_year2 === course.year &&
          compare_semester2 === course.term
      );

      return validStatus && hasInvalidGrade1 && hasInvalidGrade2;
    });

    console.log(filtratedData);
    return filtratedData;
  };

  return (
    <Grid container>
      {/*Title & Dropdown*/}
      <Grid container alignItems="center" justifyContent="space-between">
        {!gradSemester && (
          <Grid item>
            <Typography variant="h5">
              Potential Discontinue Students List
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} />
        </MainCard>
      </Grid>
    </Grid>
  );
}
