import { Accordion, Button, Spinner, Table } from 'react-bootstrap';
import { Form, Input, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { CONFIG, CURRENT, ENROLLSTATUS } from '../../ENV';
import DownloadAttachment from '../../components/DownloadAttachmentButton';
import SelectedCoursesFetch from '../CoursePlanner/hooks/SelectedCoursesFetch';
import studentServices from '../../api/services/studentServices';
import PersonalInfo from './components/PersonalInfo';
import GeneralInfo from './components/GeneralInfo';
import Background from './components/Background';
import AcademicInfo from './components/AcademicInfo';
import CurrentPOS from './components/CurrentPOS';
import NoteList from './components/Notes/NoteList';
import NotesServices from '../../api/services/notesServices';
import mediaService from '../../api/services/mediaService';
import ViewAttachment from '../../components/ViewAttachmentButton';
import NotePopup from './components/Notes/NotePopup';

export default function StudentInfo() {
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );

  // Initialize
  const location = useLocation();
  const history = useHistory();
  const id = location.pathname.split(':')[1];

  const [currentSelection, setCurrentSelection] = useState([]);
  const [notesHistory, setNotesHistory] = useState([]);
  const [student, setStudent] = useState(null);
  const [studentUpdate, setStudentUpdate] = useState(null);
  const [isDisabled1, setIsDisabled1] = useState(id > 0);
  const [isDisabled2, setIsDisabled2] = useState(id > 0);
  const [isDisabled3, setIsDisabled3] = useState(id > 0);
  const [isDisabled4, setIsDisabled4] = useState(id > 0);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [isNoteUpdate, setIsNoteUpdate] = useState(false);
  const [initNoteObj, setInitNoteObj] = useState({
    student_id: id,
    category: 'Other',
    notes: '',
    title: '',
    attachments: [],
  });
  const [initEnrollNoteObj, setInitEnrollNoteObj] = useState({
    student_id: id,
    category: '',
    notes: '',
    title: '',
    attachments: [],
  });

  const [uploadResume, setUploadResume] = useState(null);
  const [uploadAdPackage, setUploadAdPackage] = useState(null);

  const fetchConfig = {
    headers: { Authorization: `Bearer ${authenticationStatus.jwtToken}` },
  };

  const getSelectedCourses = async () => {
    const [selectionLoading, selectionError, selectionData] =
      await SelectedCoursesFetch({ studentId: id }, fetchConfig);
    setCurrentSelection(selectionData);
  };

  const getStudentNotes = async () => {
    const [notesLoading, notesError, notesData] =
      await NotesServices.FetchStudentNotes({ studentId: id }, fetchConfig);
    setNotesHistory(notesData);
  };

  useEffect(async () => {
    let studentInit = {
      id,
      first_name: '',
      middle_name: '',
      last_name: '',
      peoplesoft_id: null,
      ethnicity: '',
      // race: '',
      email: '',
      personal_email: '',
      advisor: '',
      start_term: '',
      start_year: null,
      gender: '',
      admission_gpa: '',
      year_of_birth: null,
      company: '',
      position: '',
      job_start_year: null,
      undergrad_major: '',
      prev_grad_year: null,
      street: '',
      city: '',
      state: '',
      zipcode: null,
      prev_school: '',
      status: '',
      type: '',
      track: '',
      enrollment_status: 'ACTIVE',
      title_ix_status: false,
      hipaa_training: false,
      security_clearance: false,
      medical_terminology: false,
      final_pos_grades: false,
      med_terminology_year: null,
      med_terminology_term: '',
      med_terminology_fulfill: '',
    };
    if (id && id > 0) {
      const studentData = await axios.get(
        `${CONFIG.PORTAL}/students?id=${id}`,
        fetchConfig
      );
      //this is to prequery the students' PoS
      const { data } = await axios.get(
        `${CONFIG.PORTAL}/students-courses?_limit=-1&student_id=${id}`,
        fetchConfig
      );
      // eslint-disable-next-line prefer-destructuring
      studentInit = studentData.data[0];
      studentInit.courses = [
        ...new Set(data.filter((item) => item.student_id === studentInit.id)),
      ];
    }
    setStudent(studentInit);
    setStudentUpdate(studentInit);

    // Retrieve selected courses info
    await getSelectedCourses();
    // Retrieve student notes
    await getStudentNotes();
  }, [isDisabled1, isDisabled2, isDisabled3, isDisabled4, id]);

  // save edit cancel handler
  const handleEditClick = (panelId) => {
    if (panelId === 1) {
      if (
        isDisabled2 === true &&
        isDisabled3 === true &&
        isDisabled4 === true
      ) {
        return true;
      }
      return false;
    }
    if (panelId === 2) {
      if (
        isDisabled1 === true &&
        isDisabled3 === true &&
        isDisabled4 === true
      ) {
        return true;
      }
      return false;
    }
    if (panelId === 3) {
      if (
        isDisabled1 === true &&
        isDisabled2 === true &&
        isDisabled4 === true
      ) {
        return true;
      }
      return false;
    }
    if (panelId === 4) {
      if (
        isDisabled1 === true &&
        isDisabled2 === true &&
        isDisabled3 === true
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleCancelClick = (panelId) => {
    setStudentUpdate(student);
  };

  const specialNoteDisplay = () => {
    // check whether it needs track change note
    if (student.track !== studentUpdate.track) {
      const newTitle =
        'Change from ' +
        student.track +
        ' to ' +
        studentUpdate.track +
        ' (' +
        CURRENT.YEAR +
        ' ' +
        CURRENT.TERM +
        ')';
      setShowNotePopup(true);
      setInitNoteObj({
        ...initNoteObj,
        category: 'Track Change',
        title: newTitle,
      });
    }

    // check whether it needs enrollment status change note
    if (
      student.enrollment_status !== studentUpdate.enrollment_status &&
      (studentUpdate.enrollment_status === ENROLLSTATUS.ABSENT ||
        studentUpdate.enrollment_status === ENROLLSTATUS.WITHDRAW ||
        studentUpdate.enrollment_status === ENROLLSTATUS.DISCONTINUE ||
        studentUpdate.enrollment_status === ENROLLSTATUS.DEFERRAL ||
        studentUpdate.enrollment_status === ENROLLSTATUS.PROBATION)
    ) {
      setShowNotePopup(true);
      setInitNoteObj({
        ...initEnrollNoteObj,
        category:
          studentUpdate.enrollment_status[0] +
          studentUpdate.enrollment_status.toLowerCase().substring(1),
      });
    }
  };

  const handleSaveClick = async (panelId) => {
    if (panelId < 4) {
      if (studentUpdate.first_name && studentUpdate.last_name) {
        studentUpdate.name = `${studentUpdate.first_name} ${studentUpdate.last_name}`;
      } else if (studentUpdate.first_name) {
        studentUpdate.name = studentUpdate.first_name;
      } else if (studentUpdate.last_name) {
        studentUpdate.name = studentUpdate.last_name;
      } else {
        studentUpdate.name = '';
      }
      if (id > 0) {
        try {
          await studentServices.editStudents(studentUpdate, student.id);
          specialNoteDisplay();
          setStudent(studentUpdate);
          toast.success('Edits saved successfully!');
        } catch (e) {
          toast.error('Edits saved fail, please try again: ', e);
        }
      } else {
        try {
          const res = await studentServices.addStudents(studentUpdate);
          const path = `studentinfo/:${res.data.id ? res.data.id : 0}`;
          setStudent(studentUpdate);
          history.push(path);
          toast.success('Student saved successfully!');
        } catch (e) {
          toast.error('Student saved fail, please try again: ', e);
        }
      }
    } else {
      try {
        if (uploadResume) {
          if (student.resume) {
            await mediaService.deleteFile(student.resume.id);
          }
          await mediaService.uploadResume(uploadResume, student.id);
          toast.success('Resume has been successfully saved!');
        }
        if (uploadAdPackage) {
          if (student.admission_package) {
            await mediaService.deleteFile(student.admission_package.id);
          }
          await mediaService.uploadAdDoc(uploadAdPackage, student.id);
          toast.success('Admission Package has been successfully saved!');
        }
      } catch (e) {
        toast.error('File upload error, please try again: ', e);
      }
    }

    if (panelId === 1) {
      setIsDisabled1(true);
    } else if (panelId === 2) {
      setIsDisabled2(true);
    } else if (panelId === 3) {
      setIsDisabled3(true);
    } else if (panelId === 4) {
      setIsDisabled4(true);
    }
  };

  const update = (changedValue, fieldName) => {
    let newData;
    if (fieldName === 'medical_terminology' && !changedValue) {
      newData = {
        ...studentUpdate,
        [fieldName]: changedValue,
        med_terminology_year: null,
        med_terminology_term: '',
        med_terminology_fulfill: '',
      };
    } else {
      newData = {
        ...studentUpdate,
        [fieldName]: changedValue,
      };
    }
    setStudentUpdate(newData);
  };

  return (
    <div>
      {studentUpdate !== null ? (
        <div>
          <NotePopup
            studentObj={studentUpdate}
            getStudentNotes={getStudentNotes}
            showNotePopup={showNotePopup}
            setShowNotePopup={setShowNotePopup}
            initNoteObj={initNoteObj}
            setInitNoteObj={setInitNoteObj}
            isNoteUpdate={isNoteUpdate}
            setIsNoteUpdate={setIsNoteUpdate}
          />
          {/*Panel #1*/}
          <PersonalInfo history={history} studentObj={studentUpdate} />
          {/*Panel #2* General Information*/}
          <GeneralInfo
            studentObj={studentUpdate}
            isDisabled={isDisabled1}
            setIsDisabled1={setIsDisabled1}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            update={update}
          />
          {/*Panel #3* Background*/}
          <Background
            studentObj={studentUpdate}
            isDisabled={isDisabled2}
            setIsDisabled2={setIsDisabled2}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            update={update}
          />
          {/*Panel #4* Academic Information*/}
          <AcademicInfo
            studentObj={studentUpdate}
            isDisabled={isDisabled3}
            setIsDisabled3={setIsDisabled3}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            update={update}
          />

          {/*Panel #5* Attachments*/}
          {/*<Attachments*/}
          {/*  studentObj={studentUpdate}*/}
          {/*  isDisabled={isDisabled4}*/}
          {/*  setIsDisabled4={setIsDisabled4}*/}
          {/*  handleEditClick={handleEditClick}*/}
          {/*  handleSaveClick={handleSaveClick}*/}
          {/*/>*/}

          <Accordion
            defaultActiveKey={['0']}
            alwaysOpen
            style={{ marginTop: '20px' }}
            hidden={id <= 0}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Attachments</Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td>
                          <Label for="resume">Resume/CV</Label>
                          <Input
                            disabled={isDisabled4}
                            id="resume"
                            name="resume"
                            type="file"
                            onChange={(e) => setUploadResume(e.target.files[0])}
                          />
                          {!student.resume ? (
                            <Label>Saved File: Empty</Label>
                          ) : (
                            <>
                              <Label>{student.resume.name}</Label>
                              <DownloadAttachment
                                url={CONFIG.PORTAL + student.resume.url}
                                filename={student.resume.name}
                              />
                              <ViewAttachment
                                url={CONFIG.PORTAL + student.resume.url}
                                filename={student.resume.name}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td />
                      </tr>
                      <tr>
                        <td>
                          <Label for="ad_package">Admission Package</Label>
                          <Input
                            disabled={isDisabled4}
                            id="resume"
                            name="resume"
                            type="file"
                            onChange={(e) =>
                              setUploadAdPackage(e.target.files[0])
                            }
                          />
                          {!student.admission_package ? (
                            <Label>Saved File: Empty</Label>
                          ) : (
                            <>
                              <Label>{student.admission_package.name}</Label>
                              <DownloadAttachment
                                url={
                                  CONFIG.PORTAL + student.admission_package.url
                                }
                                filename={student.admission_package.name}
                              />
                              <ViewAttachment
                                url={CONFIG.PORTAL + student.resume.url}
                                filename={student.admission_package.name}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Button
                    className={
                      isDisabled4 && handleEditClick(4)
                        ? 'col-1 me-3 btn-primary'
                        : 'col-1 me-3 btn-secondary'
                    }
                    onClick={() => {
                      if (handleEditClick(4)) {
                        setIsDisabled4(!isDisabled4);
                      }
                    }}
                    hidden={id <= 0}
                  >
                    {' '}
                    {isDisabled4 ? 'Edit ' : ' Cancel '}
                  </Button>
                  <Button
                    className={
                      isDisabled4
                        ? 'col-1 me-3 btn-secondary'
                        : 'col-1 me-3 btn-primary'
                    }
                    onClick={() => handleSaveClick(4)}
                    hidden={id <= 0}
                  >
                    {' '}
                    Save{' '}
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <Spinner
          className="justify-content-center"
          animation="border"
          role="status"
        />
      )}
      {/*{*/}
      {/*  // Current POS panel*/}
      {/*  currentSelection.length !== 0 && id > 0 ? (*/}
      {/*    <CurrentPOS currentSelection={currentSelection} />*/}
      {/*  ) : (*/}
      {/*    <br />*/}
      {/*  )*/}
      {/*}*/}
      {
        // Notes panel
        notesHistory.length !== 0 && id > 0 ? (
          <NoteList
            notesHistory={notesHistory}
            getStudentNotes={getStudentNotes}
            setShowNotePopup={setShowNotePopup}
            setInitNoteObj={setInitNoteObj}
            setInitEnrollNoteObj={setInitEnrollNoteObj}
            setIsNoteUpdate={setIsNoteUpdate}
          />
        ) : (
          <br />
        )
      }
    </div>
  );
}
