import axios from 'axios';
import { CONFIG } from '../../../ENV';

/**
 *
 * @param {Object} Pbody Post body
 * @param {function} actionsAfterThen function that will be executed after the then
 * @param {function} actionsAfterCatch functions that will be executed after the catch
 * @returns return the response of the request (success or error)
 */
export default async function UploadGrades(
  Pbody,
  actionsAfterThen,
  actionsAfterCatch
) {
  return axios
    .post(`${CONFIG.PORTAL}/students-courses/uploadGradesByFile`, Pbody, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('authenticationStatus')).jwtToken
        }`,
      },
    })
    .then((resp) => {
      actionsAfterThen(resp);
      return resp;
    })
    .catch((error) => {
      actionsAfterCatch(error);
      return error;
    });
}
