import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

function NoteTextField({ note, setNote }) {
  const [localValue, setLocalValue] = useState(note);

  useEffect(() => {
    setLocalValue(note);
  }, [note]);

  const localHandleNote = (event) => {
    setLocalValue(event.target.value);
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };

  return (
    <>
      <TextField
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        value={localValue || ''}
        onChange={localHandleNote}
        onBlur={handleNote}
      />
    </>
  );
}

export default NoteTextField;
