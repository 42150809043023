import React from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Bar } from '@ant-design/plots';
import MainCard from '../../../components/MainCard';
import useChartStat from '../../../api/hooks/useChartStat';

function AdvisorChart() {
  const { data: advisorData, isLoading } = useChartStat(
    '/students/getAdvisorStat'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width={210} height={60} />;
  }

  const config = {
    data: advisorData,
    height: 500,
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    legend: {
      position: 'top-left',
    },
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Advisor</Typography>
        </Grid>
        <Grid item />
      </Grid>
      <MainCard sx={{ mt: 1 }} content={false}>
        <Box sx={{ p: 3 }}>
          <Bar {...config} />
        </Box>
      </MainCard>
    </Grid>
  );
}

export default AdvisorChart;
