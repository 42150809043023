import React from 'react';
import { Table } from 'react-bootstrap';
import { isEmpty } from 'underscore';
import { Badge } from 'reactstrap';
import { Grid, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

function TermPanel({ currentSelection, programSemesters }) {
  const displayCourseSelection = (displayArr) => {
    return displayArr.map((obj, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{obj.courseId}</TableCell>
          <TableCell>
            {obj.courseTitle}
            {obj.mark.isConflict ? (
              <Badge className="bg-warning text-dark">CONFLICT</Badge>
            ) : (
              ''
            )}
            {obj.mark.isValidity ? (
              ''
            ) : (
              <Badge className="bg-secondary text-light">ERROR</Badge>
            )}
          </TableCell>
          <TableCell>{obj.courseDay}</TableCell>
          <TableCell>{obj.courseTime}</TableCell>
        </TableRow>
      );
    });
  };

  const displaySemesterPanel = () => {
    return programSemesters.map((semester, index) => {
      // Push into array according to semester
      let sliceYear = semester.slice(0, 4);
      let sliceTerm = semester.slice(4, semester.length);
      let displayArr = [];
      currentSelection?.forEach((obj, index) => {
        if (
          obj.courseYear &&
          obj.courseYear.toString() === sliceYear &&
          obj.courseTerm === sliceTerm
        ) {
          displayArr.push({
            ...obj,
            mark: {
              isConflict: false,
              isValidity: true,
            },
          });
        }
      });

      // Conflict Checksum
      for (let i = 0; i < displayArr.length; i++) {
        let orgObj = displayArr[i];
        if (
          isEmpty(orgObj.courseDay) ||
          isEmpty(orgObj.courseTime) ||
          isEmpty(orgObj.courseTerm)
        ) {
          orgObj.mark.isValidity = false;
        }

        for (let j = 0; j < displayArr.length; j++) {
          let copObj = displayArr[j];
          if (
            copObj.courseId !== orgObj.courseId &&
            copObj.courseDay !== 'INDEP' &&
            copObj.courseTerm === orgObj.courseTerm &&
            copObj.courseYear === orgObj.courseYear &&
            copObj.courseTime === orgObj.courseTime &&
            copObj.courseDay === orgObj.courseDay
          ) {
            orgObj.mark.isConflict = true;
            copObj.mark.isConflict = true;
          }
        }
      }

      return (
        <Grid
          item
          key={index}
          sx={{
            height: '100%',
            minWidth: '650px',
            border: 'lightgrey solid 1px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  {semester}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Course Title</TableCell>
                <TableCell>Day</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{displayCourseSelection(displayArr)}</TableBody>
          </Table>
        </Grid>
      );
    });
  };

  return (
    // <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
    //
    // </div>
    <Grid container direction="column">
      {displaySemesterPanel()}
    </Grid>
  );
}

export default TermPanel;
