import React from 'react';
import { Modal, Box, Typography, Button, Divider, Stack } from '@mui/material';
import { Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { parseISO, getYear } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid grey.100',
  boxShadow: 10,
  borderRadius: 2,
  p: 4,
  flexDirection: 'column',
  display: 'flex',
  flexWrap: 'wrap',
};

export default function TermAndYearSelectionModal({
  open,
  handleClose,
  handleSubmitFunction,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { term: '', year: null } });

  const handleYearChange = (date) => {
    if (date) {
      return parseISO(getYear(date).toString());
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={modalStyle}
        component="form"
        onSubmit={handleSubmit(handleSubmitFunction)}
      >
        <Typography variant="h5" id="modal-title">
          Select Term and Year
        </Typography>
        <Typography variant="caption">
          Please select the term and year this grade spreadsheet is for and
          click submit.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={1.5}>
          <Typography variant="h6">Year</Typography>
          <Controller
            name="year"
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              // <Input {...field} label="Year" variant="outlined" type="date" />
              <DatePicker
                {...fieldProps}
                selected={handleYearChange(value)}
                className="form-control"
                placeholderText=" "
                yearItemNumber={10}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          />
          <Typography component="label" variant="h6">
            Term
          </Typography>
          <Controller
            name="term"
            control={control}
            render={({ field: { defaultValue, ...fieldProps } }) => (
              <Input
                {...fieldProps}
                label="term"
                type="select"
                defaultValue={defaultValue}
                placeholder="Select"
              >
                <option value="" disabled>
                  {' '}
                </option>
                <option value="Spring">Spring</option>
                <option value="Summer">Summer</option>
                <option value="Fall">Fall</option>
              </Input>
            )}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
