import React from 'react';

const InfoData = {
  termOptions: [
    { value: 'Summer', label: 'Summer' },
    { value: 'Spring', label: 'Spring' },
    { value: 'Fall', label: 'Fall' },
  ],
  ethnicityOptions: [
    { value: 'White/Caucasian', label: 'White/Caucasian' },
    {
      value: 'American Indian/Alaska Native',
      label: 'American Indian/Alaska Native',
    },
    { value: 'Asian', label: 'Asian' },
    { value: 'Black/African American', label: 'Black/African American' },
    { value: 'Hispanic/Latino', label: 'Hispanic/Latino' },
    { value: 'Pacific Islander', label: 'Pacific Islander' },
    { value: 'Multi-Racial', label: 'Multi-Racial' },
    { value: 'Not Specified', label: 'Not Specified' },
  ],
  raceOptions: [
    'White/Caucasian',
    'American Indian/Alaska Native',
    'Asian',
    'Black/African American',
    'Hispanic/Latino',
    'Pacific Islander',
    'Not Specified',
  ],
  genderOptions: [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Prefer not to say', label: 'Prefer not to say' },
  ],
  statusOptions: [
    { value: 'FT', label: 'Full Time' },
    { value: 'PT', label: 'Part Time' },
    { value: '', label: '' },
  ],
  typeOptions: [
    { value: 'ONLINE', label: 'ONLINE' },
    { value: 'ONCMP', label: 'ON CAMPUS' },
  ],
  trackOptions: [
    {
      value: 'HSM',
      label: 'Healthcare Supervision and Management',
      type: 'Graduate',
    },
    { value: 'HI', label: 'Health Informatics', type: 'Graduate' },
    { value: 'DS', label: 'Data Science', type: 'Graduate' },
    {
      value: 'RHIA',
      label: 'Registered Health Information Administrator',
      type: 'Graduate',
    },
    { value: 'HSA', label: 'Health Services Analytics', type: 'Graduate' },
    {
      value: 'CHIC',
      label: 'Certificate in Health Information Cybersecurity',
      type: 'Certification',
    },
    {
      value: 'CHDA',
      label: 'Certificate in Health Data Analytics',
      type: 'Certification',
    },
    {
      value: 'CLHI',
      label: 'Certificate in Leadership in Health Informatics',
      type: 'Certification',
    },
    {
      value: 'CRCM',
      label: 'Certificate in Revenue Cycle Management',
      type: 'Certification',
    },
  ],
  initEnrollmentStatusOptions: [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'ABSENT', label: 'Absent' },
    { value: 'GRADUATE', label: 'Graduate' },
  ],
  enrollmentStatusOptions: [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'ABSENT', label: 'Absent' },
    { value: 'PROBATION', label: 'Probation' },
    { value: 'GRADUATE', label: 'Graduate' },
    { value: 'DISCONTINUE', label: 'Discontinue' },
    { value: 'DEFERRAL', label: 'Deferral' },
    { value: 'WITHDRAW', label: 'Withdraw' },
  ],
  noteOptions: [
    { value: 'General Advising', label: 'General Advising' },
    { value: 'Initial Advising', label: 'Initial Advising' },
    { value: 'Scholarship', label: 'Scholarship' },
    { value: 'Other', label: 'Other' },
  ],
  medTerminologyFulFilledOption: [
    {
      label: 'Equivalent Course',
      value: 'Equivalent Course',
      abbr: 'Equivalent',
      hidden: false,
    },
    {
      label: 'Coursera Course',
      value: 'Coursera Course',
      abbr: 'Coursera',
      hidden: false,
    },
    {
      label: 'Clinical Experience',
      value: 'Clinical Experience',
      abbr: 'Clin. Exp.',
      hidden: false,
    },
    { label: 'Waiver', value: 'Waiver', abbr: 'Waiver', hidden: true },
  ],
};

const getMedFulfilledAbbr = (fullname) => {
  const options = InfoData.medTerminologyFulFilledOption;
  for (let i = 0; i < options.length; i++) {
    if (options[i].label === fullname) {
      return options[i].abbr;
    }
  }
  return '';
};

export default InfoData;

export { getMedFulfilledAbbr };
