import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import SemesterDropDown from './SemesterDropDown';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { ENROLLSTATUS } from '../../ENV';

export default function GraduatedReport() {
  const { studentList, appearedSemester, isLoading, isError } =
    useStuListByAdv();
  const [gradSemester, setGradSemester] = useState('');

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const handleChangeTerm = (e) => {
    const term = e.target.value;
    setGradSemester(term);
  };

  const mutateData = () => {
    let filtratedData = studentList.filter(
      (item) => item.enrollment_status === ENROLLSTATUS.GRADUATE
    );

    if (gradSemester) {
      filtratedData = studentList.filter(
        (item) =>
          item.concatGradTerm === gradSemester &&
          item.enrollment_status === ENROLLSTATUS.GRADUATE
      );
    }
    return filtratedData;
  };

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        {!gradSemester && (
          <Grid item>
            <Typography variant="h5">Graduate Students List</Typography>
          </Grid>
        )}
        {gradSemester && (
          <Grid item>
            <Typography variant="h5">
              {gradSemester}: {mutateData().length} student(s) graduate{' '}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <SemesterDropDown
            title="Select graduate semester"
            value={gradSemester}
            onChange={handleChangeTerm}
            appearedSemester={appearedSemester}
          />
        </Grid>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} />
        </MainCard>
      </Grid>
    </Grid>
  );
}
