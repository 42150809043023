import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';

export default function HeaderButtons() {
  const history = useHistory();

  const backToAdmin = () => {
    history.push('/admin');
  };

  const reImportDocument = () => {
    window.location.reload();
  };

  return (
    <ButtonGroup disableElevation size="small">
      <Button variant="contained" color="primary" onClick={() => backToAdmin()}>
        <ArrowBackIcon sx={{ mr: 0.5 }} />
        Back to Admin
      </Button>
      <Button
        variant="contained"
        color="success"
        onClick={() => reImportDocument()}
      >
        <RotateRightOutlinedIcon sx={{ mr: 0.5 }} />
        Re-Import current File
      </Button>
    </ButtonGroup>
  );
}
