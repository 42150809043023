import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../../../index.css';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { Upload } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { toast } from 'react-hot-toast';
import MainCard from '../../../components/MainCard';
import StyledDropzone from './StyledDropzone';
import UploadStudentCSV from '../connectors/UploadStudentCSV';
import studentServices from '../../../api/services/studentServices';
import UpdateToCertification from '../connectors/updateToCertification';
import UpdateToDegree from '../connectors/updateToDegree';
import StudentGrid from '../../../components/StudentGrid';

export default function AdminImportStudents() {
  const fabStyle = {
    margin: 0,
    top: 'auto',
    right: 25,
    bottom: 20,
    left: 'auto',
    zIndex: 1500,
    position: 'absolute',
  };

  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [sanityCheckAlert, setsanityCheckAlert] = useState('');
  const [sanityCheckRes, setsanityCheckRes] = useState(true);
  const userName = useRef(localStorage.getItem('currentUserName'));
  const [parUploading, setparUploading] = useState(false);
  const [successImport, setSuccessImport] = useState([]);
  const [failImport, setFailImport] = useState([]);
  const [updateDegree, setUpdateDegree] = useState([]);
  const [updateCertification, setUpdateCertification] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [newStudentSelectionModel, setNewStudentSelectionModel] = useState([]);
  const [
    degreeUpdateStudentSelectionModel,
    setDegreeUpdateStudentSelectionModel,
  ] = useState([]);
  const [
    certificationUpdateStudentSelectionModel,
    setCertificationUpdateStudentSelectionModel,
  ] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(userName.current);
    setFiles(acceptedFiles);
    //acceptedFiles
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    onDrop,
  });
  const fileList = files.map((file) => (
    <div key={file.path}>
      <div>Selected File: {file.name}</div>
    </div>
  ));

  function backToAdmin() {
    history.push('/admin');
  }

  function reImportDocument() {
    setparUploading(!parUploading);
  }

  async function uploadDocument() {
    let sanityCheck = true;
    let response = '';

    if (files.length === 0) {
      response += 'No Roster File included';
      sanityCheck = false;
      toast.error('No File');
    }

    if (sanityCheck) {
      const res = await UploadStudentCSV(files[0]);
      if (res.status === 200) {
        setsanityCheckRes(true);
        setparUploading(!parUploading);
        setSuccessImport(res.data.success_record);
        setFailImport(res.data.exist_record);
        setUpdateDegree(res.data.update_degree);
        setUpdateCertification(res.data.update_certification);
      } else {
        setsanityCheckRes(false);
        toast.error(`Import Failed ${res ? ': ' + res : ''}`);
      }
    } else {
      setsanityCheckRes(false);

      setsanityCheckAlert(
        response.split('\n').map((str) => (
          <li key={str}>
            <span>{str}</span>
          </li>
        ))
      );
    }
  }

  const getSonMsg = useCallback(async (sonMsg) => {
    if (sonMsg.status === 1) {
      const importList = [];
      sonMsg.import.forEach((element) => {
        importList.push(successImport[element]);
      });
      if (importList.length == 0) {
        toast.error('Empty List');
      } else {
        const confirmed = window.confirm(
          'Are you sure you want to import these students?'
        );
        if (confirmed) {
          const res = await studentServices.importStudents(importList);
          if (res.status && res.status === 200) {
            setRefresh(refresh + 1);
            toast.success('Successfully imported!');
          } else {
            toast.error('Import Failed');
          }
        }
      }
    } else if (sonMsg.status === 2) {
      const importList = [];
      sonMsg.import.forEach((element) => {
        importList.push(updateDegree[element]);
      });
      if (importList.length == 0) {
        toast.error('Empty List');
      } else {
        const confirmed = window.confirm(
          "Are you sure you want to update these students' degree?"
        );
        if (confirmed) {
          const res = await UpdateToDegree(importList);
          if (res.status && res.status === 200) {
            setRefresh(refresh + 1);
            toast.success('Successfully Update!');
          } else {
            toast.error('Update Failed');
          }
        }
      }
    } else {
      const importList = [];
      sonMsg.import.forEach((element) => {
        importList.push(updateCertification[element]);
      });
      if (importList.length == 0) {
        toast.error('Empty List');
      } else {
        const confirmed = window.confirm(
          "Are you sure you want to update these students' certification?"
        );
        if (confirmed) {
          const res = await UpdateToCertification(importList);
          if (res.status && res.status === 200) {
            setRefresh(refresh + 1);
            toast.success('Successfully Update!');
          } else {
            toast.error('Update Failed');
          }
        }
      }
    }
  });

  useEffect(async () => {
    if (files.length > 0) {
      const res = await UploadStudentCSV(files[0]);
      setsanityCheckRes(true);
      setSuccessImport(res.data.success_record);
      setFailImport(res.data.exist_record);
      setUpdateDegree(res.data.update_degree);
      setUpdateCertification(res.data.update_certification);
    }
  }, [refresh]);

  const failColumn = [
    { field: 'id', hide: true },
    { field: 'peoplesoft_id', headerName: 'PeopleSoft Id', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'track', headerName: 'Track', width: 150 },
    {
      field: 'start_year',
      headerName: 'Start Year',
      width: 150,
    },
    {
      field: 'failReason',
      headerName: 'Reason',
      width: 150,
      valueGetter: (params) =>
        params.row.failReason === 'Certification Exist'
          ? params.row.failReason
          : 'Degree Exist',
    },
  ];

  const columns = [
    { field: 'id', hide: true },
    { field: 'peoplesoft_id', headerName: 'PeopleSoft Id', width: 200 },
    { field: 'first_name', headerName: 'First Name', width: 200 },
    { field: 'last_name', headerName: 'Last Name', width: 200 },
    { field: 'track', headerName: 'Track', width: 150 },
    { field: 'start_term', headerName: 'Start Term', width: 150 },
    { field: 'start_year', headerName: 'Start Year', width: 150 },
  ];

  return (
    <section style={{ width: '100%' }}>
      {/*Drag and drop page */}
      <div style={{ width: '100%' }} hidden={parUploading}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Auto import students by file
        </Typography>
        <MainCard
          sx={{ p: 0 }}
          title={`Selected File: ${
            files.length > 0 ? files[0].name : 'undefined'
          }`}
          hasActions
          buttonGroup={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => backToAdmin()}
              >
                <ArrowBackIcon sx={{ mr: 0.5 }} />
                Back to Admin
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => uploadDocument()}
              >
                <ArrowUpwardIcon sx={{ mr: 0.5 }} />
                Submit file
              </Button>
            </Stack>
          }
        >
          <StyledDropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isFocused={isFocused}
            isDragActive={isDragActive}
            selectedFileTitle={files.name}
          />
        </MainCard>
      </div>

      {/* Preview Page */}
      <div
        style={{ width: '100%' }}
        hidden={!parUploading}
        className="centeredLoad"
      >
        <div style={{ width: '100%' }}>
          <ButtonGroup size="large" style={fabStyle}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => backToAdmin()}
            >
              <ArrowBackIcon sx={{ mr: 0.5 }} />
              Back{'  '}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => reImportDocument()}
            >
              <Upload sx={{ mr: 0.5 }} />
              Re-Import
            </Button>
          </ButtonGroup>
          <MainCard
            title="Successful: New Students"
            sx={{ mt: 2 }}
            hasActions
            buttonGroup={
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  getSonMsg({
                    status: 1,
                    import: newStudentSelectionModel,
                  });
                }}
              >
                Import
              </Button>
            }
          >
            {/*Preview Tables*/}
            <StudentGrid
              rows={successImport.map((row, index) => ({
                ...row,
                id: index,
              }))}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setNewStudentSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={newStudentSelectionModel}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
            {/* <ImportTable
                tableList={successImport}
                status={1}
                tableName="New Students"
                getSonMsg={getSonMsg}
              /> */}
          </MainCard>

          <MainCard
            title="Student Originally in Certification, Converting to Full Degree"
            sx={{ mt: 2 }}
            hasActions
            buttonGroup={
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  getSonMsg({
                    status: 2,
                    import: degreeUpdateStudentSelectionModel,
                  });
                }}
              >
                Update
              </Button>
            }
          >
            <StudentGrid
              rows={updateDegree.map((row, index) => ({
                ...row,
                id: index,
              }))}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setDegreeUpdateStudentSelectionModel(newRowSelectionModel);
              }}
              rowSelectioModel={degreeUpdateStudentSelectionModel}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
            {/* <ImportTable
                tableList={updateDegree}
                status={2}
                tableName="Student Originally in Certification, Converting to Full Degree"
                getSonMsg={getSonMsg}
              /> */}
          </MainCard>

          <MainCard
            title="Student is in Full Degree Program or Other Certification Program and Adding Certification"
            sx={{ mt: 2 }}
            hasActions
            buttonGroup={
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  getSonMsg({
                    status: 3,
                    import: certificationUpdateStudentSelectionModel,
                  });
                }}
              >
                Update
              </Button>
            }
          >
            <StudentGrid
              rows={updateCertification.map((row, index) => ({
                ...row,
                id: index,
              }))}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setCertificationUpdateStudentSelectionModel(
                  newRowSelectionModel
                );
              }}
              rowSelectioModel={certificationUpdateStudentSelectionModel}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
            {/* <ImportTable
                tableList={updateCertification}
                status={3}
                tableName="Student is in Full Degree Program and Adding Certification"
                getSonMsg={getSonMsg}
                />*/}
          </MainCard>

          <MainCard title=" Cannot Import Student into Database" sx={{ mt: 2 }}>
            <StudentGrid
              rows={failImport.map((row, index) => ({ ...row, id: index }))}
              columns={failColumn}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
            />
          </MainCard>
        </div>
      </div>
    </section>
  );
}
