import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import { GridActionsCellItem } from '@mui/x-data-grid';
import NotesServices from '../../../../api/services/notesServices';
import MediaService from '../../../../api/services/mediaService';
import { EnrollStatusNeedNote } from '../../../../ENV';
import StudentGrid from '../../../../components/StudentGrid';

export default function NoteList({
  notesHistory,
  getStudentNotes,
  setShowNotePopup,
  setInitNoteObj,
  setIsNoteUpdate,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const handleDeleteDialogOpen = (item) => {
    setIsDeleteDialogOpen(true);
    setDeleteItem(item);
  };
  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setDeleteItem('');
  };

  const handleEditNoteOpen = (item) => {
    setIsNoteUpdate(true);
    setShowNotePopup(true);
    setInitNoteObj(item);
  };

  const deleteNote = async (item) => {
    const attachmentIds = [];
    item.attachments.forEach((attachment) => {
      attachmentIds.push(attachment.id);
    });
    try {
      if (attachmentIds.length > 0) {
        await MediaService.deleteFiles(attachmentIds);
      }
      await NotesServices.DeleteNotes(item.id);
      toast.success('Successfully Deleted.');
    } catch (e) {
      toast.error('Delete Failed: ', e);
    }
    await getStudentNotes();
    setIsDeleteDialogOpen(false);
    setDeleteItem('');
  };

  const displayRender = (category, note, title) => {
    let displayContent = title || note;
    if (EnrollStatusNeedNote.includes(category)) {
      if (category === 'Absent') {
        displayContent = `LOA until ${title || note}`;
      }
    }
    return displayContent;
  };

  const headerConfig = [
    {
      field: 'title',
      headerName: 'Title',
      width: 400,
      valueGetter: ({ row }) =>
        displayRender(row.category, row.notes, row.title),
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 160,
    },
    {
      field: 'created_at',
      headerName: 'Create At',
      width: 150,
      type: 'dateTime',
      valueGetter: ({ value }) =>
        value && new Date(new Date(value).toLocaleString()),
    },
    {
      field: 'updated_at',
      headerName: 'Update At',
      width: 150,
      type: 'dateTime',
      valueGetter: ({ value }) =>
        value && new Date(new Date(value).toLocaleString()),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      getActions: (params) => {
        const item = params.row;
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            onClick={() => handleEditNoteOpen(item)}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={() => handleDeleteDialogOpen(item)}
          />,
        ];
      },
    },
  ];

  return (
    <div>
      <Accordion defaultActiveKey={['0']} style={{ marginTop: '20px' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Notes</Accordion.Header>
          <Accordion.Body>
            <StudentGrid
              rows={notesHistory}
              columns={headerConfig}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'created_at', sort: 'desc' }],
                },
              }}
              style={{ paddingLeft: '18px' }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/*Delete Confirm Dialog*/}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Note</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
          <Button onClick={() => deleteNote(deleteItem)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
