import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import DownloadButton from '../components/DownloadAttachmentButton';
import useUploadFiles from '../api/hooks/useUploadFiles';
import SemesterDropDown from './StudentReport/SemesterDropDown';
import MainCard from '../components/MainCard';
import StudentTable from './StudentReport/StudentTable';
import StudentGrid from '../components/StudentGrid';
import { CONFIG } from '../ENV';

function DownloadCard({ fileName, fileType, fileDescription, url }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {fileType}
        </Typography>
        <Typography variant="h5" component="div">
          {fileName}
        </Typography>
        <Typography variant="body2">
          File desc placeholder - Uploaded file in system
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <DownloadButton url={url} />
      </CardActions>
    </Card>
  );
}

function FileTable({ data }) {
  const renderButton = (params) => (
    <DownloadButton url={CONFIG.PORTAL + params.row.url} />
  );

  const headConfig = [
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      editable: true,
    },
    {
      field: 'fileType',
      headerName: 'File Type',
      width: 150,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 120,
      type: 'actions',
      renderCell: renderButton,
      // disableClickEventBubbling: true,
      // disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
    },
  ];

  return <StudentGrid rows={data} columns={headConfig} />;
}

function DownloadPage(props) {
  const { data, error, isLoading } = useUploadFiles('upload/files');

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error...</div>;

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">File list</Typography>
        </Grid>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <FileTable data={data} />
        </MainCard>
      </Grid>

      {/*{data.map((item, index) => (*/}
      {/*  <Grid item>*/}
      {/*    <DownloadCard*/}
      {/*      key={item.id}*/}
      {/*      fileName={item.name}*/}
      {/*      fileType={item.fileType}*/}
      {/*      url={CONFIG.PORTAL + item.url}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*))}*/}
    </Grid>
  );
}

export default DownloadPage;
